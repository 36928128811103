<template>
  <div class="px-6 pb-16 pt-8 flex-1 flex flex-col" v-show="!isLoading">
    <div>
      <router-link :to="{ name: 'account' }">
        <div
          class="h-32 w-32 bg-cover bg-center bg-cover rounded-4xl mx-auto"
          :style="`background-image:url('${user.profile_picture}')`"
        ></div>
      </router-link>
      <div class="mt-4 text-center">
        <h1 class="text-2xl font-medium text-gray-700" v-if="user">
          {{ $t("dashboard.hello") }}, {{ user.first_name }}
        </h1>
        <p class="text-gray-700">{{ $t("dashboard.your_locations") }} :</p>
      </div>
    </div>

    <div
      class="mt-8 grid grid-flow-col overflow-x-auto gap-x-4 auto-cols-max overflow-y-hidden"
      style="grid-auto-columns: 100%;"
    >
      <div v-for="company in companies" :key="company.id">
        <div class="w-full">
          <router-link
            :to="{ name: 'company-cards', params: { id: company.id } }"
          >
            <div
              :style="
                `background-image: url('${
                  company.image
                    ? company.image
                    : 'https://i.pinimg.com/originals/51/0d/12/510d122c1550b89d0fe56d1b4c8df2d6.jpg'
                }');`
              "
              class="w-full h-128 relative rounded-lg bg-cover bg-center rounded-3xl overflow-y-hidden"
            >
              <div class="absolute bottom-0 p-5 w-full">
                <div
                  class="text-white bg-yellow-primary-500 px-3 py-1.5 rounded-t-xl rounded-br-xl flex inline-flex"
                >
                  <svg
                    class="text-white w-5 h-5 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="font-semibold text-sm">{{
                    $t("dashboard.prepaid_card")
                  }}</span>
                </div>
                <div
                  class="rounded-b-3xl rounded-tr-3xl bg-white flex items-center justify-center w-full py-3.5 px-6 mt-2"
                >
                  <div class="w-full">
                    <p class="text-gray-700 text-xl font-bold tracking-wide">
                      {{ company.brand }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: true
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(vm.companies);
      if (vm.companies.length === 1) {
        vm.$router.push({
          name: "company-cards",
          params: { id: vm.companies[0].id }
        });
      }
      setTimeout(() => {
        vm.isLoading = false;
      }, 1000);
    });
  },

  computed: {
    ...mapState("auth", ["user"]),

    companies() {
      return this.user.companies;
    }
  },

  methods: {}
};
</script>
