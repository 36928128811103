var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"fixed z-10 inset-0 overflow-y-auto",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"flex items-center justify-center min-h-screen text-center p-0"},[_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",class:{
        'ease-out duration-30 opacity-100': _vm.isOpen,
        'ease-in duration-200 opacity-0': !_vm.isOpen
      },attrs:{"aria-hidden":"true"},on:{"click":_vm.close}}),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-center bg-white rounded-2xl px-12 pt-5 pb-4 text-left shadow-xl transform transition-all max-w-limit w-full mx-4",class:{
        'opacity-100 translate-y-0 sm:scale-100': _vm.isOpen,
        'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !_vm.isOpen
      }},[_c('div',{staticClass:"block absolute top-0 right-0 pt-4 pr-4"},[_c('button',{staticClass:"bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close")]),_c('svg',{staticClass:"h-6 w-6",attrs:{"x-description":"Heroicon name: outline/x","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])]),(_vm.card)?_c('div',[_vm._m(0),_c('div',{staticClass:"text-center -mt-4"},[_c('h3',{staticClass:"text-2xl leading-6 font-medium text-gray-800",attrs:{"id":"modal-title"}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.ask_use_pass_title"))+" ")]),_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.ask_use_pass_desc"))+" ")])])])]):_vm._e(),_c('div',{staticClass:"mt-5 sm:mt-6"},[_c('a',{staticClass:"w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-3 border border-transparent",attrs:{"href":_vm.card.apple_pass}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.ask_use_pass_btn"))+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto relative flex items-center justify-center h-32 w-32 -mt-20"},[_c('img',{staticClass:"z-50 block w-20",attrs:{"src":require("@/assets/imgs/wallet_app_icon.png")}})])
}]

export { render, staticRenderFns }