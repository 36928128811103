import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Home from "@/views/Home";
import Login from "@/views/Login";
import Register from "@/views/Register";
import ResetPassword from "@/views/ResetPassword";
import UpdatePassword from "@/views/UpdatePassword";

import Dashboard from "@/views/Dashboard";

import CompanyCards from "@/views/Company/Cards";
import InvitationShow from "@/views/Invitations/Show";
import PrepaidCardShow from "@/views/PrepaidCards/Show";
import PrepaidCardConfirm from "@/views/PrepaidCards/Confirm";

import LoyaltyCardShow from "@/views/LoyaltyCards/Show";
import InvitationLoyaltyProgram from "@/views/LoyaltyPrograms/Invitation";
import LoyaltyCardConfirm from "@/views/LoyaltyCards/Confirm";

import OfferShow from "@/views/Offers/Show";

import AccountMain from "@/views/Account/Main";
import AccountHistory from "@/views/Account/History";
import AccountUpdatePassword from "@/views/Account/UpdatePassword";
import AccountUpdateEmail from "@/views/Account/UpdateEmail";
import AccountUpdatePhone from "@/views/Account/UpdatePhone";
import AccountAvatar from "@/views/Account/Avatar";
import AccountHowItWorks from "@/views/Account/HowItWorks";
import AccountInstallApp from "@/views/Account/InstallApp";

import PrivacyPolicy from "@/views/PrivacyPolicy";

import Page404 from "@/views/Error/Page404";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      showLangPicker: true
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      showLangPicker: true
    }
  },
  {
    path: "/password/reset",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      showLangPicker: true
    }
  },
  {
    path: "/password/update/:token",
    name: "update-password-token",
    component: UpdatePassword,
    meta: {
      showLangPicker: true
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/company/:id/cards",
    name: "company-cards",
    component: CompanyCards,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/account",
    name: "account",
    component: AccountMain,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/account/history",
    name: "history",
    component: AccountHistory,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/account/update/password",
    name: "update-password",
    component: AccountUpdatePassword,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/account/update/email",
    name: "update-email",
    component: AccountUpdateEmail,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/account/update/phone",
    name: "update-phone",
    component: AccountUpdatePhone,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/account/avatar",
    name: "avatar",
    component: AccountAvatar,
    meta: {
      guard: ["contact"],
      showLangPicker: true
    }
  },
  {
    path: "/account/how-it-works",
    name: "how-it-works",
    component: AccountHowItWorks,
    meta: {
      guard: ["contact"],
      showLangPicker: true
    }
  },
  {
    path: "/account/install-app",
    name: "install-app",
    component: AccountInstallApp,
    meta: {
      guard: ["contact"],
      showLangPicker: true
    }
  },
  {
    path: "/invitations/:id",
    name: "invitation-show",
    component: InvitationShow,
    meta: {
      guard: [],
      forceAuth: false,
      showLangPicker: true
    }
  },
  {
    path: "/prepaid-card/:id/confirm",
    name: "prepaid-card-confirm",
    component: PrepaidCardConfirm,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/prepaid-card/:id",
    name: "prepaid-card-show",
    component: PrepaidCardShow,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/loyalty-card/:id",
    name: "loyalty-card-show",
    component: LoyaltyCardShow,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/offer/:id",
    name: "offer-show",
    component: OfferShow,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/loyalty-card/:id/confirm",
    name: "loyalty-card-confirm",
    component: LoyaltyCardConfirm,
    meta: {
      guard: ["contact"]
    }
  },
  {
    path: "/loyalty-program/:id/join/:lId",
    name: "loyalty-program-join",
    component: InvitationLoyaltyProgram,
    meta: {
      guard: [],
      forceAuth: false,
      showLangPicker: true
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      guard: [],
      forceAuth: false,
      showLangPicker: true
    }
  },
  {
    path: "*",
    name: "error-404",
    component: Page404
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((routeTo, routeFrom, next) => {
  const guards = routeTo.meta.guard;
  const forceAuth = routeTo.meta.forceAuth === true;

  if (guards && guards.length > 0) {
    store.dispatch("auth/user").then(res => {
      if (res.status !== 200 && forceAuth) {
        next({ name: "login", query: { redirect: routeTo.path } });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
