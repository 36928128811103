import axios from "axios";

export default {
  getOneInvitation(uuid, params = null) {
    return axios.get(`api/contact/prepaid-cards/invitations/${uuid}`, {
      params: params
    });
  },

  acceptInvitation(uuid) {
    return axios.post(`api/contact/prepaid-cards/invitations/${uuid}/accept`);
  },

  getOneCard(uuid, params = null) {
    return axios.get(`api/contact/prepaid-cards/${uuid}`, {
      params: params
    });
  },

  getHistory(params = null) {
    return axios.get(`api/contact/prepaid-cards/history`, {
      params: params
    });
  },

  getFullHistory(params = null) {
    return axios.get(`api/contact/prepaid-cards/history/full`, {
      params: params
    });
  }
};
