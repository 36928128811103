<template>
  <div class="pt-8 pb-20 flex-1 flex flex-col bg-white px-6">
    <div v-if="error">
      <p class="text-red-primary-700">{{ error }}</p>
    </div>
    <div v-else-if="card" class="flex flex-1 flex-col justify-center">
      <div class="w-full h-16 mb-6" v-if="company.logo">
        <img class="max-h-full mx-auto" :src="company.logo" />
      </div>

      <div class="flex justify-center">
        <prepaid-card :company="company" :prepaidCard="card" />
      </div>
      <div class="pb-6">
        <div class="mt-8 text-sm px-6 text-center">
          <p class="text-gray-800 text-2xl font-semibold">
            {{ $t("dashboard.just_used_invitation") }} <br />
            <span class="text-yellow-primary-500">{{
              $t("global.prepaid_card")
            }}</span
            ><br />
            {{ company.brand }}
          </p>
        </div>
      </div>

      <div class="mt-4 mb-8 text-center">
        <router-link
          :to="{
            name: $route.query.redirect
              ? $route.query.redirect
              : 'prepaid-card-show',
            params: { id: card.id },
            query: { wallet: this.openAskDownloadPass }
          }"
          class="px-12 text-white bg-blue-primary-500 rounded-lg py-3.5 border border-transparent"
        >
          <span>Ok</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import PrepaidCard from "@/components/Cards/PrepaidCard";

import prepaidCardApi from "@/api/prepaid-card";

export default {
  components: {
    PrepaidCard
  },

  data() {
    return {
      cardUuid: null,
      card: null,
      error: null,
      openAskDownloadPass: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("global", ["isIos"]),

    company() {
      return this.card ? this.card.prepaidCardModel.company : null;
    }
  },

  mounted() {
    this.cardUuid = this.$route.params.id;
    this.getPrepaidCard();
  },

  methods: {
    getPrepaidCard() {
      prepaidCardApi
        .getOneCard(this.cardUuid, {
          include:
            "prepaidCardModel,prepaidCardModel.company,prepaidCardHistories"
        })
        .then(res => {
          this.card = res.data;

          if (this.isIos) {
            this.openAskDownloadPass = true;
          }
        })
        .catch(err => {
          console.log(err.response);
          this.error = this.$t("dashboard.invitation_not_found");
        });
    }
  }
};
</script>
