<template>
  <div class="flex items-center py-4">
    <div
      class="text-yellow-primary-800 bg-yellow-primary-100 p-2 rounded mt-2"
      v-if="history.type === 'HISTORY'"
    >
      <svg
        class="w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
        ></path>
      </svg>
    </div>

    <div
      class="text-blue-primary-500 bg-blue-primary-100 p-2 rounded mt-2"
      v-else-if="history.type === 'CARD'"
    >
      <svg
        class="w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        ></path>
      </svg>
    </div>

    <div class="flex flex-col pl-4" v-if="history.type === 'HISTORY'">
      <p class="text-sm text-gray-400">
        {{ $t("global.The") }} {{ history.created_at | date_dmy }}
        {{ $t("global.at") }}
        {{ history.created_at | date_hm }}
      </p>
      <p class="font-medium text-gray-800 leading-5 mt-1.5">
        {{ $t("account.history_section.you_order") }}
        {{ products.map(p => `${p.pivot.number} ${p.name}`).join(", ") }}
        {{ $t("account.history_section.with_prepaid_card") }}
        {{ history.location.name }}
      </p>
    </div>
    <div class="flex flex-col pl-4" v-else-if="history.type === 'CARD'">
      <p class="text-sm text-gray-400">
        {{ $t("global.The") }} {{ history.created_at | date_dmy }}
        {{ $t("global.at") }}
        {{ history.created_at | date_hm }}
      </p>
      <p class="font-medium text-gray-800 leading-5 mt-1.5">
        {{ $t("account.history_section.card_unlock") }}
        {{ prepaidCardModel.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    history: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  computed: {
    type() {
      return this.history.type;
    },

    prepaidCardModel() {
      if (this.type === "HISTORY") {
        return this.history.prepaid_card.prepaid_card_invitation
          .prepaid_card_batch.prepaid_card_model;
      } else if (this.type === "CARD") {
        return this.history.prepaid_card_invitation.prepaid_card_batch
          .prepaid_card_model;
      } else {
        return null;
      }
    },

    products() {
      if (this.type === "HISTORY") {
        return this.prepaidCardModel.products;
      } else {
        return null;
      }
    }
  },

  filters: {
    date_dmy: value => {
      let d = new Date(value),
        day = "" + d.getDate(),
        month = d.toLocaleString("fr-FR", { month: "short" }),
        year = d.getFullYear();

      if (day.length < 2) day = "0" + day;

      return [day, month, year].join(" ");
    },

    date_hm: value => {
      let d = new Date(value),
        minutes = "" + d.getMinutes(),
        hours = d.getHours();

      if (minutes.length < 2) minutes = "0" + minutes;

      return `${hours}H${minutes}`;
    }
  }
};
</script>
