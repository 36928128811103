import Vue from "vue";
import App from "./App.vue";

import "./registerServiceWorker";

import router from "./router";
import store from "./store";

import i18n from "@/helpers/i18n";

import axios from "axios";

import axiosSetup from "@/helpers/interceptors";

import "@/assets/scss/tailwind.scss";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

axiosSetup();

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
