const state = {
  loader: false
};

const actions = {
  enableLoader({ commit }) {
    commit("ENABLE_LOADER");
  },
  disableLoader({ commit }) {
    commit("DISABLE_LOADER");
  }
};

const mutations = {
  ENABLE_LOADER(state) {
    state.loader = true;
  },
  DISABLE_LOADER(state) {
    state.loader = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
