var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{ name: 'loyalty-card-show', params: { id: _vm.loyaltyCard.id } }}},[_c('div',{staticClass:"w-full rounded-2xl bg-pink-primary-500 px-7 py-4"},[_c('div',{staticClass:"w-full text-right mt-1 mb-1"},[_c('p',{staticClass:"text-white font-medium uppercase"},[_vm._v(" "+_vm._s(_vm.$t("global.loyalty"))+" ")])]),_c('div',{staticClass:"w-full mt-4"},[_c('p',{staticClass:"text-white text-3xl font-bold tracking-wide"},[_vm._v(" "+_vm._s(_vm.loyaltyCard.points_validated)+" "+_vm._s(_vm.loyaltyCard.points_validated > 1 ? "points" : "point")+" ")]),(_vm.lastProductGiftUnlockable)?_c('p',{staticClass:"text-white text-xs"},[_vm._v(" "+_vm._s(_vm.$t("cards.gift_available"))+" : "),(_vm.lastProductGiftUnlockable.target)?_c('span',[_vm._v(_vm._s(_vm.lastProductGiftUnlockable.target.name))]):_c('span',[_vm._v(_vm._s(_vm.lastProductGiftUnlockable.label))])]):_c('p',{staticClass:"text-white text-xs"},[_vm._v(" - ")])]),_c('div',{staticClass:"w-full mt-4 mb-4"},[_c('div',{staticClass:"flex items-center w-full"},[_c('div',{staticClass:"rounded-full px-2 py-1.5 bg-white"},[_c('img',{staticClass:"h-6 w-6",attrs:{"src":_vm.lastProductGiftUnlockable &&
              _vm.lastProductGiftUnlockable.target.picto
                ? _vm.lastProductGiftUnlockable.target.picto
                : require('@/assets/imgs/cupcake.png')}})]),_c('div',{staticClass:"w-full ml-2 h-2.5 relative max-w rounded-full overflow-hidden"},[_c('div',{staticClass:"w-full h-full rounded-full bg-white absolute"}),_c('div',{staticClass:"transition-all ease-out duration-1000 h-full bg-pink-primary-100 relative",style:(`width: ${
                _vm.nextProductGiftUnlockable
                  ? (_vm.loyaltyCard.points_validated /
                      _vm.nextProductGiftUnlockable.cost_points) *
                    100
                  : 0
              }%;`)})])]),_c('div',{staticClass:"w-full text-right"},[(_vm.nextProductGiftUnlockable)?_c('div',[_c('p',{staticClass:"text-white mb-0 text-sm font-medium"},[_vm._v(" "+_vm._s(_vm.$t("cards.next_step"))+" : ")]),(_vm.nextProductGiftUnlockable.target)?_c('p',{staticClass:"text-white text-xs font-light"},[_vm._v(" "+_vm._s(_vm.nextProductGiftUnlockable.target.name)+" ")]):_c('p',{staticClass:"text-white text-xs font-light"},[_vm._v(" "+_vm._s(_vm.nextProductGiftUnlockable.label)+" ")])]):_c('div',[_c('p',{staticClass:"text-white mb-0 text-sm font-medium"},[_vm._v(" "+_vm._s(_vm.$t("cards.no_next_step"))+" ")]),_c('p',{staticClass:"text-white text-xs font-light"},[_vm._v("-")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }