import axios from "axios";
import store from "@/store";
import router from "@/router";

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;

export default function setup() {
  axios.interceptors.request.use(
    config => {
      store.dispatch("loader/enableLoader");

      config.headers["Content-Type"] = "application/json";
      config.headers["Accept"] = "application/json";
      return config;
    },
    error => {
      store.dispatch("loader/disableLoader");

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      store.dispatch("loader/disableLoader");

      return response;
    },
    error => {
      store.dispatch("loader/disableLoader");

      const notRedirect404 = ["invitations"];

      const { status } = error.response;
      const user = store.getters["auth/user"];

      if (status === UNAUTHORIZED) {
        if (
          error.response.config.url === "api/contact/auth/user" &&
          error.response.config.data
        ) {
          let body = JSON.parse(error.response.config.data);
          if (body.locale && body._method === "PUT") {
            store.commit("auth/SET_USER", null);
          } else {
            store.commit("auth/SET_USER", null);
            router.push({ name: "login" });
          }
        } else if (error.response.config.url === "logout") {
          store.commit("auth/SET_USER", null);
          router.push({ name: "login" });
        } else if (
          error.response.config.url === "auth/user" ||
          error.response.config.url === "api/contact/auth/user"
        ) {
          store.commit("auth/SET_USER", null);
          router.push({ name: "login" });
        } else if (!user) {
          router.push({ name: "login" });
        }
      } else if (
        status === NOT_FOUND &&
        notRedirect404.filter(r => error.response.config.url.includes(r))
          .length === 0
      ) {
        router.push({ name: "error-404" });
      }

      return Promise.reject(error);
    }
  );
}
