<template>
  <router-link
    class="w-full h-full"
    :to="{ name: 'offer-show', params: { id: offer.id } }"
  >
    <div class="w-full h-full rounded-2xl bg-green-500 px-7 py-4">
      <div class="w-full text-right mt-1 mb-3">
        <p class="text-white font-medium uppercase">
          {{ $t("global.offer") }}
        </p>
      </div>
      <div class="w-full mt-4">
        <p class="text-white text-2xl font-bold tracking-wide leading-5">
          {{ offer.label }}
        </p>
      </div>

      <div class="w-full mt-4 mb-2 md:mt-8 md:mb-4">
        <p class="text-white text-sm font-medium leading-4">
          {{ recurringLabel }}{{ limitUsageLabel }}{{ endAtLabel }}.
        </p>
        <p class="text-white text-xs font-light"></p>
        <div
          class="mt-4 h-2.5 relative max-w rounded-full overflow-hidden"
          v-if="offer.nb_limit_usage"
        >
          <div class="w-full h-full rounded-full bg-green-100 absolute"></div>
          <div
            class="transition-all ease-out duration-1000 h-full bg-white relative"
            :style="
              `width: ${(contactOfferRecurrenceUsagesCount /
                offer.nb_limit_usage) *
                100}%;`
            "
          ></div>
        </div>
        <div v-else class="h-8"></div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },

  props: {
    offer: {
      type: Object,
      required: true
    },

    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    recurringLabel() {
      if (!this.offer.is_recurring) {
        return this.$t("offer.usage_one_time");
      } else if (this.offer.nb_limit_usage) {
        return `${this.$t("offer.usage")} ${
          this.offer.nb_limit_usage
        } ${this.$t("offer.time")}`;
      } else {
        return this.$t("offer.usage_infinite");
      }
    },

    limitUsageLabel() {
      if (!this.offer.limit_usage) {
        return ", " + this.$t("offer.online_offline");
      } else if (this.offer.limit_usage === "online") {
        return ", " + this.$t("offer.online");
      } else if (this.offer.limit_usage === "on-site") {
        return ", " + this.$t("offer.offline");
      } else {
        return null;
      }
    },

    endAtLabel() {
      if (!this.offer.ended_at) {
        return ", " + this.$t("offer.no_time_limit");
      } else {
        return `, ${this.$t("offer.before_the")} ${new Date(
          this.offer.ended_at.replace(/ /g, "T")
        ).toLocaleString("fr-FR", {
          month: "short",
          day: "2-digit",
          year: "numeric"
        })}`;
      }
    },

    contactOfferRecurrenceUsagesCount() {
      return this.offer.contactOfferHistories.length;
    }
  },

  methods: {}
};
</script>
