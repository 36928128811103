<template>
  <div v-if="user" class="flex-1 flex flex-col bg-gray-primary-800">
    <div
      class="pb-24 h-96 bg-cover bg-center bg-cover"
      :style="`background-image:url('${user.profile_picture}')`"
    >
      <div class="flex items-center justify-between px-6 pt-8">
        <router-link
          :to="{
            name: backButtonRoute,
            params: { id: backButtonRouteId ? backButtonRouteId : null }
          }"
        >
          <svg
            class="w-8 h-8 text-white -ml-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </router-link>
        <div class="flex flex-col items-end">
          <select-lang />
          <router-link
            :to="{ name: 'avatar' }"
            class="text-white underline text-xs mt-2"
          >
            {{ $t("account.update_avatar") }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="mt-8 px-6 py-8 rounded-t-4xl -mt-8 bg-white flex-1">
      <router-link
        :to="{ name: item.route }"
        class="flex justify-between items-center mb-6"
        v-for="item in menu"
        :key="item.label"
      >
        <div class="flex items-center">
          <span
            class="p-2 rounded-lg"
            :class="item.iconClasses"
            v-html="item.icon"
          >
          </span>
          <p class="pl-8 text-gray-700 font-medium text-lg">
            {{ $t(item.label) }}
          </p>
        </div>
        <div class="text-gray-500">
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </div>
      </router-link>

      <div class="mt-12">
        <router-link
          class="flex justify-between items-centerlm mt-8"
          :to="{ name: 'update-password' }"
        >
          <div>
            <p>
              {{ $t("global.Password") }}
            </p>
          </div>
          <div>
            <a href="#" class="text-gray-300">
              {{ $t("global.Update") }}
            </a>
          </div>
        </router-link>

        <!-- <router-link
          class="flex justify-between items-centerlm mt-8"
          :to="{ name: 'update-email' }"
        >
          <div>
            <p>
              {{ $t("global.Email") }}
            </p>
          </div>
          <div>
            <p class="text-gray-300">
              {{ user.email }}
            </p>
          </div>
        </router-link> -->

        <router-link
          class="flex justify-between items-centerlm mt-8"
          :to="{ name: 'update-phone' }"
        >
          <div>
            <p>
              {{ $t("global.Phone") }}
            </p>
          </div>
          <div>
            <p class="text-gray-300">
              {{ user.phone }}
            </p>
          </div>
        </router-link>
      </div>

      <!-- <div class="mt-12 mb-8">
        <p class="text-lg font-medium leading-5 text-gray-800">
          {{ $t("account.how_add_app_home_screen_question") }}
        </p>
        <p class="text-gray-400 text-sm mt-4">
          {{ $t("account.how_add_app_home_screen_answer") }}
        </p>
      </div> -->

      <div class="mt-10 text-center w-full">
        <button
          type="button"
          @click.prevent="logout"
          class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
        >
          {{ $t("global.logout") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SelectLang from "@/components/SelectLang";

import auth from "@/api/auth";

export default {
  components: {
    SelectLang
  },

  data() {
    return {
      menu: [
        {
          label: "account.history",
          route: "history",
          icon:
            '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" > <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" ></path> </svg>',
          iconClasses: "bg-yellow-primary-100 text-yellow-primary-700"
        },
        {
          label: "account.how_it_works",
          route: "how-it-works",
          icon:
            '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>',
          iconClasses: "bg-green-primary-100 text-green-primary-700"
        },
        {
          label: "account.install_app",
          route: "install-app",
          icon:
            '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>',
          iconClasses: "bg-red-primary-100 text-red-primary-700"
        },
        {
          label: "account.my_locations",
          route: "dashboard",
          icon:
            '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>',
          iconClasses: "bg-purple-primary-100 text-purple-primary-700"
        }
      ]
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    backButtonRoute() {
      if (this.user.companies.length === 1) {
        if (
          this.user.companies[0].contactPrepaidCards.length === 1 &&
          this.user.companies[0].contactLoyaltyCards.length === 0
        ) {
          return "prepaid-card-show";
        } else if (
          this.user.companies[0].contactLoyaltyCards.length === 1 &&
          this.user.companies[0].contactPrepaidCards.length === 0
        ) {
          return "loyalty-card-show";
        } else {
          return "company-cards";
        }
      } else {
        return "dashboard";
      }
    },

    backButtonRouteId() {
      if (!this.user) {
        return null;
      }
      if (this.user.companies.length === 1) {
        if (
          this.user.companies[0].contactPrepaidCards.length === 1 &&
          this.user.companies[0].contactLoyaltyCards.length === 0
        ) {
          return this.user.companies[0].contactPrepaidCards[0].id;
        } else if (
          this.user.companies[0].contactLoyaltyCards.length === 1 &&
          this.user.companies[0].contactPrepaidCards.length === 0
        ) {
          return this.user.companies[0].contactLoyaltyCards[0].id;
        } else {
          return this.user.companies[0].id;
        }
      } else {
        return null;
      }
    }
  },

  methods: {
    updateProfilePicture() {
      const image = this.$refs.profilePicture.files[0];

      var formData = new FormData();
      formData.append("profile_picture", image);
      formData.append("_method", "PUT");

      auth.update(formData).then(res => {
        console.log(res.data);
        this.$store.commit("auth/SET_USER", res.data);
      });
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>
