<template>
  <div class="-mt-12 pt-8 flex-1 flex flex-col bg-white px-6">
    <div v-if="error">
      <div class="flex flex-col items-center justify-center">
        <div>
          <img src="@/assets/imgs/404.png" />
        </div>
        <div class="text-center px-16 mt-12">
          <p class="text-2xl font-semibold">{{ $t("error.oh_no") }}</p>
          <p class="text-xl text-gray-500 mt-2">
            {{ error }}
          </p>
        </div>

        <div class="mt-10 text-center w-full px-8">
          <router-link
            class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
            :to="{
              name: backButtonRoute,
              params: { id: backButtonRouteId ? backButtonRouteId : null }
            }"
          >
            {{ $t("error.back_on_dashboard") }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-else-if="invitation && !isLoading">
      <div class="w-full">
        <img
          v-if="invitation.prepaid_card_model.company.logo"
          class="mx-auto"
          :src="invitation.prepaid_card_model.company.logo"
        />
        <img v-else class="mx-auto" src="@/assets/imgs/cards-blue-black.svg" />
      </div>
      <div class="pb-6">
        <div class="mt-8 text-sm px-12 text-center">
          <p class="text-gray-800 text-2xl font-semibold">
            {{ $t("dashboard.welcome") }},
          </p>
          <p class="text-lg text-gray-500 leading-5 mt-4">
            {{ $t("dashboard.you_will_accept_invitation") }}
          </p>
        </div>
      </div>

      <div class="mt-8 mb-8">
        <router-link
          :to="{
            name: 'register',
            query: { redirect: $router.currentRoute.fullPath }
          }"
          class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-3.5 border border-transparent"
        >
          <span>{{ $t("dashboard.register_invitation") }}</span>
        </router-link>
      </div>

      <div>
        <p class="text-center text-sm text-gray-700">
          {{ $t("dashboard.already_register_invitation") }}
          <router-link
            :to="{
              name: 'login',
              query: { redirect: $router.currentRoute.fullPath }
            }"
            class="text-blue-primary-500 font-semibold"
            >{{ $t("dashboard.login_invitation") }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import prepaidCard from "@/api/prepaid-card";

export default {
  data() {
    return {
      invitationUuid: null,
      invitation: null,
      error: null,
      isLoading: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    backButtonRoute() {
      if (!this.user) {
        return "dashboard";
      }
      if (this.user.companies.length === 1) {
        if (this.user.companies[0].contactPrepaidCards.length > 1) {
          return "company-cards";
        } else {
          return "prepaid-card-show";
        }
      } else {
        return "dashboard";
      }
    },

    backButtonRouteId() {
      if (!this.user) {
        return null;
      }
      if (this.user.companies.length === 1) {
        if (this.user.companies[0].contactPrepaidCards.length > 0) {
          return this.user.companies[0].contactPrepaidCards[0].id;
        } else {
          return this.user.companies[0].contactLoyaltyCards[0].id;
        }
      } else {
        return null;
      }
    }
  },

  mounted() {
    this.invitationUuid = this.$route.params.id;
    this.getInvitation();
  },

  methods: {
    getInvitation() {
      this.isLoading = true;
      prepaidCard
        .getOneInvitation(this.invitationUuid, {
          include: "prepaid_card_model.company"
        })
        .then(res => {
          console.log(res.status);
          this.invitation = res.data;

          if (this.user) {
            this.acceptInvitation();
          } else {
            this.isLoading = false;
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.status);
          if (err.response.status === 405) {
            // already used
            this.error = this.$t("dashboard.invitation_already_used");
          } else if (err.response.status === 406) {
            // batch not active
            this.error = this.$t("dashboard.invitation_batch_not_active");
          } else {
            this.error = this.$t("dashboard.invitation_not_found");
          }
        });
    },

    acceptInvitation() {
      prepaidCard
        .acceptInvitation(this.invitationUuid)
        .then(res => {
          console.log(res.data);
          this.$router.push({
            name: "prepaid-card-confirm",
            params: {
              id: res.data.id
            },
            query: {
              redirect: this.$route.query.redirect
            }
          });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.isLoading = false;
          }
        });
    }
  }
};
</script>
