<template>
  <router-link
    :to="{ name: 'loyalty-card-show', params: { id: loyaltyCard.id } }"
  >
    <div class="w-full rounded-2xl bg-pink-primary-500 px-7 py-4">
      <div class="w-full text-right mt-1 mb-1">
        <p class="text-white font-medium uppercase">
          {{ $t("global.loyalty") }}
        </p>
      </div>
      <div class="w-full mt-4">
        <p class="text-white text-3xl font-bold tracking-wide">
          {{ loyaltyCard.points_validated }}
          {{ loyaltyCard.points_validated > 1 ? "points" : "point" }}
        </p>
        <p class="text-white text-xs" v-if="lastProductGiftUnlockable">
          {{ $t("cards.gift_available") }} :
          <span v-if="lastProductGiftUnlockable.target">{{
            lastProductGiftUnlockable.target.name
          }}</span>
          <span v-else>{{ lastProductGiftUnlockable.label }}</span>
        </p>
        <p class="text-white text-xs" v-else>
          -
        </p>
      </div>

      <div class="w-full mt-4 mb-4">
        <div class="flex items-center w-full">
          <div class="rounded-full px-2 py-1.5 bg-white">
            <img
              class="h-6 w-6"
              :src="
                lastProductGiftUnlockable &&
                lastProductGiftUnlockable.target.picto
                  ? lastProductGiftUnlockable.target.picto
                  : require('@/assets/imgs/cupcake.png')
              "
            />
          </div>
          <div
            class="w-full ml-2 h-2.5 relative max-w rounded-full overflow-hidden"
          >
            <div class="w-full h-full rounded-full bg-white absolute"></div>
            <div
              class="transition-all ease-out duration-1000 h-full bg-pink-primary-100 relative"
              :style="
                `width: ${
                  nextProductGiftUnlockable
                    ? (loyaltyCard.points_validated /
                        nextProductGiftUnlockable.cost_points) *
                      100
                    : 0
                }%;`
              "
            ></div>
          </div>
        </div>
        <div class="w-full text-right">
          <div v-if="nextProductGiftUnlockable">
            <p class="text-white mb-0 text-sm font-medium">
              {{ $t("cards.next_step") }} :
            </p>
            <p
              class="text-white text-xs font-light"
              v-if="nextProductGiftUnlockable.target"
            >
              {{ nextProductGiftUnlockable.target.name }}
            </p>
            <p class="text-white text-xs font-light" v-else>
              {{ nextProductGiftUnlockable.label }}
            </p>
          </div>
          <div v-else>
            <p class="text-white mb-0 text-sm font-medium">
              {{ $t("cards.no_next_step") }}
            </p>
            <p class="text-white text-xs font-light">-</p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },

  props: {
    loyaltyCard: {
      type: Object,
      required: true
    },

    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    sortedGifts() {
      return [...this.loyaltyCard.loyalty_program_gifts]
        .filter(g => g.is_active)
        .sort(function(g1, g2) {
          return g1.cost_points - g2.cost_points;
        });
    },

    productGifts() {
      return [...this.loyaltyCard.loyalty_program_gifts]
        .filter(g => g.target)
        .sort((g1, g2) => {
          return g1.cost_points - g2.cost_points;
        });
    },

    hasSortedGifts() {
      return this.sortedGifts.length > 0;
    },

    hasProductGifts() {
      return this.productGifts.length > 0;
    },

    lastProductGiftUnlockable() {
      if (this.productGifts.length === 0) {
        return null;
      }

      let unlockableGifts = this.productGifts.filter(
        p => p.cost_points <= this.loyaltyCard.points_validated
      );

      if (unlockableGifts.length === 0) {
        return null;
      }

      return unlockableGifts[unlockableGifts.length - 1];
    },

    nextProductGiftUnlockable() {
      if (!this.hasSortedGifts) {
        return null;
      }

      let returnNext = false;
      let next = null;

      this.sortedGifts.forEach(p => {
        if (returnNext && !next) {
          next = p;
        } else if (
          this.lastProductGiftUnlockable &&
          p.id === this.lastProductGiftUnlockable.id &&
          !next
        ) {
          returnNext = true;
        } else if (!this.lastProductGiftUnlockable && !next) {
          next = p;
        }
      });

      return next ? next : null;
    }
  },

  methods: {}
};
</script>
