<template>
  <div
    class="min-h-screen bg-white flex flex-col items-center sm:px-0 relative bg-purple-primary-900"
  >
    <div class="w-full flex-1 sm:max-w-xl py-6 flex flex-col">
      <div>
        <div class="w-full">
          <img class="mx-auto" src="@/assets/imgs/hello.svg" />
        </div>

        <div class="mt-12 px-6 rounded-md sm:px-10">
          <p class="text-gray-400 text-lg leading-5 mt-2 font-medium">
            {{ $t("auth.password.fill_form") }}
          </p>

          <div class="py-4">
            <div v-show="error">
              <p class="text-red-600">{{ error }}</p>
            </div>
            <div v-show="success">
              <p class="text-green-600">{{ success }}</p>
            </div>
            <form action="#" method="POST" @submit.prevent="reset">
              <div class="mt-4 sm:mt-6">
                <label
                  for="email"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Email") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/mail -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="email"
                    id="email"
                    :placeholder="$t('global.Email')"
                    type="email"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
              </div>
              <div class="mt-12 text-center">
                <button
                  type="submit"
                  class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
                >
                  {{ $t("auth.password.send_email") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="text-center text-sm font-light text-gray-700">
        <p>
          {{ $t("auth.register.already_register") }}
          <router-link
            class="text-blue-primary-500 font-semibold"
            :to="{ name: 'login', query: Object.assign({}, $route.query) }"
            >{{ $t("auth.register.login") }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import auth from "@/api/auth";

export default {
  data() {
    return {
      email: "",
      success: null,
      error: null
    };
  },

  mounted() {
    if (this.user) {
      this.$router.push({ name: "dashboard" });
    }
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    reset() {
      const body = {
        email: this.email
      };

      auth.cookie().then(() => {
        auth
          .askResetPassword(body)
          .then(res => {
            if (res.status && res.status === 204) {
              this.success = this.$t("auth.password.success_send_email");
              this.error = null;
            } else {
              this.error = this.$t("auth.password.can_not_reset");
              this.success = null;
            }
          })
          .catch(() => {
            this.error = this.$t("auth.password.can_not_reset");
            this.success = null;
          });
      });
    }
  }
};
</script>
