import axios from "axios";

export default {
  getOne(uuid, params = null) {
    return axios.get(`api/contact/offers/${uuid}`, {
      params: params
    });
  },

  get(params = null) {
    return axios.get("api/contact/offers", {
      params: params
    });
  }
};
