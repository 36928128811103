import auth from "@/api/auth";

const state = {
  user: null,
  token: ""
};

const getters = {
  token: state => {
    return state.token;
  },
  user: state => {
    return state.user;
  }
};

const actions = {
  login({ commit }, data) {
    return auth
      .login(data)
      .then(response => {
        if (response.status === 200) {
          commit("SET_USER", response.data);
        }
        return response;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  },

  register({ commit }, data) {
    return auth
      .register(data)
      .then(response => {
        if (response.status === 200) {
          commit("SET_USER", response.data);
        }
        return response;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  },

  user({ commit }) {
    return auth
      .user({
        include:
          "companies.contactPrepaidCards,companies.contactLoyaltyCards,companies.contactOffers"
      })
      .then(response => {
        if (response.status === 200) {
          commit("SET_USER", response.data);
        }
        return response;
      })
      .catch(error => {
        console.log("error " + error);
        commit("SET_USER", null);
        return error.response;
      });
  },

  logout({ commit }, data) {
    return auth
      .logout(data)
      .then(response => {
        commit("SET_USER", null);
        return response;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  }
};

const mutations = {
  SET_USER(state, value) {
    state.user = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
