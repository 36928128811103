<template>
  <div class="pt-2 flex-1 flex flex-col bg-white">
    <div class="flex flex-col px-6">
      <router-link :to="{ name: 'account' }">
        <svg
          class="w-8 h-8 text-gray-600 -ml-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>
      <div class="mt-4">
        <h1
          class="text-3xl font-semibold text-gray-900 leading-8 tracking-wide"
        >
          {{ $t("auth.avatar.choose_my") }} <br />
          {{ $t("auth.avatar.avatar") }} 🧐
        </h1>
      </div>
    </div>

    <div class="w-full mt-6 px-6">
      <div class="grid grid-cols-2 gap-x-4 gap-y-4 pb-28">
        <div
          class="cursor-pointer relative p-1 border border-transparent hover:border-pink-primary-500 rounded-2xl"
          :class="{ 'border-pink-primary-500': i === selectedIndex }"
          @click="selectAvatar(i)"
          v-for="(avatar, i) in avatars"
          :key="`avatar-${i}`"
        >
          <div
            class="rounded-full absolute top-0 right-0 mr-4 mt-4 p-1 border border-white"
            :class="{ hidden: i !== selectedIndex }"
          >
            <div class="bg-white rounded-full w-3 h-3"></div>
          </div>
          <img class="rounded-2xl" :src="avatar" />
        </div>
      </div>
    </div>
    <div
      class="max-w-limit w-full text-center fixed z-50 px-8 bottom-0 py-6 bg-white"
    >
      <button
        type="button"
        @click="updateAvatar"
        :disabled="isDisabled"
        class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
        :class="{ 'opacity-50 cursor-not-allowed': isDisabled }"
      >
        {{ $t("global.Confirm") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import auth from "@/api/auth";

export default {
  data() {
    return {
      selectedIndex: null
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    avatars() {
      const nbs = [
        1,
        4,
        6,
        10,
        12,
        13,
        15,
        16,
        20,
        26,
        29,
        30,
        33,
        40,
        41,
        42,
        56,
        61,
        68,
        70,
        74,
        85,
        86,
        92,
        94,
        99
      ];

      let avatars = nbs.map(nb =>
        require(`@/assets/imgs/avatar/ToyFaces_Colored_BG_${nb}.jpg`)
      );

      for (let i = avatars.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [avatars[i], avatars[j]] = [avatars[j], avatars[i]];
      }

      return avatars;
    },

    isDisabled() {
      return this.selectedIndex === null;
    }
  },

  methods: {
    selectAvatar(index) {
      this.selectedIndex = index;
    },

    updateAvatar() {
      var formData = new FormData();

      this.readImage(this.avatars[this.selectedIndex], base64 => {
        formData.append("profile_picture", base64);
        formData.append("_method", "PUT");

        auth
          .update(formData)
          .then(() => {
            this.$router.push(
              this.$route.query.redirect || { name: "account" }
            );
          })
          .catch(err => {
            console.log(err.response);
          });
      });
    },

    readImage(url, callback) {
      var request = new XMLHttpRequest();
      request.onload = function() {
        var file = new FileReader();
        file.onloadend = function() {
          callback(file.result);
        };
        file.readAsDataURL(request.response);
      };
      request.open("GET", url);
      request.responseType = "blob";
      request.send();
    }
  }
};
</script>
