<template>
  <div
    class="min-h-screen bg-white flex flex-col items-center sm:px-0 relative bg-purple-primary-900"
  >
    <div class="w-full flex-1 sm:max-w-xl py-6 flex flex-col">
      <div>
        <div class="mt-12 px-6 rounded-md sm:px-10">
          <div class="text-2xl font-semibold tracking-wider font-blogger-sans">
            {{ $t("auth.register.register") }} 🕺
          </div>
          <p class="text-gray-400 text-lg leading-5 mt-2 font-medium">
            {{ $t("auth.register.fill_form") }}
          </p>

          <div class="py-4">
            <form action="#" method="POST" @submit.prevent="register">
              <div class="mt-4 sm:mt-6">
                <label
                  for="first_name"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Firstname") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/user -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="data.first_name"
                    id="first_name"
                    :placeholder="$t('global.Firstname')"
                    type="text"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
              </div>
              <div class="mt-4 sm:mt-6">
                <label
                  for="email"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Email") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/mail -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="data.email"
                    id="email"
                    :placeholder="$t('global.Email')"
                    type="email"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
                <div
                  v-if="errors.email && errors.email.length > 0"
                  class="mt-2 px-1"
                >
                  <p
                    v-for="(error, i) in errors.email"
                    :key="`error-email-${i}`"
                    class="text-red-500 text-sm"
                  >
                    {{
                      $t(
                        "error." +
                          error
                            .replace(".", "")
                            .split(" ")
                            .join("_")
                            .toLowerCase()
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="mt-4 sm:mt-6">
                <label
                  for="password"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Password") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/lock-closed -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="data.password"
                    id="password"
                    :placeholder="$t('global.Password')"
                    type="password"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
                <div
                  v-if="errors.password && errors.password.length > 0"
                  class="mt-2 px-1"
                >
                  <p
                    v-for="(error, i) in errors.password"
                    :key="`error-password-${i}`"
                    class="text-red-500 text-sm"
                  >
                    {{
                      $t(
                        "error." +
                          error
                            .replace(".", "")
                            .split(" ")
                            .join("_")
                            .toLowerCase()
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="mt-4 sm:mt-6">
                <label
                  for="password"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Password_confirmation") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/lock-closed -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="data.password_confirmation"
                    id="password_confirmation"
                    :placeholder="$t('global.Password_confirmation')"
                    type="password"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
              </div>

              <div class="mt-4 sm:mt-6">
                <label
                  for="password"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Phone") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/phone -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="data.phone"
                    id="phone"
                    :placeholder="$t('global.Phone')"
                    type="phone"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>

                <div class="mt-6 flex items-center px-1">
                  <div>
                    <input
                      v-model="data.marketing_opt_in"
                      id="marketing_opt_in"
                      name="marketing_opt_in"
                      type="checkbox"
                      class="h-4 w-4 text-blue-primary-500 focus:ring-blue-primary-700 border-gray-300 rounded"
                    />
                  </div>
                  <label for="marketing_opt_in" class="ml-3 block">
                    <p
                      class="text-sm text-gray-500"
                      v-html="$t('auth.register.authorise_marketing')"
                    ></p>
                  </label>
                </div>
              </div>

              <div class="mt-8 text-center">
                <button
                  type="submit"
                  class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
                >
                  {{ $t("auth.register.submit_button") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="text-center text-sm font-light text-gray-700">
        <p>
          {{ $t("auth.register.already_register") }}
          <router-link
            class="text-blue-primary-500 font-semibold"
            :to="{ name: 'login', query: Object.assign({}, $route.query) }"
            >{{ $t("auth.register.login") }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import auth from "@/api/auth";

export default {
  data() {
    return {
      data: {
        first_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        marketing_opt_in: true
      },
      errors: []
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("global", [
      "isIos",
      "isAndroid",
      "isSamsung",
      "isFirefox",
      "isStandalone"
    ])
  },

  methods: {
    register() {
      const body = { ...this.data, locale: this.$i18n.locale };

      auth.cookie().then(() => {
        this.$store
          .dispatch("auth/register", body)
          .then(res => {
            if (res.status && res.status === 200) {
              if (this.isAndroid && !this.isStandalone) {
                this.$router.push({
                  name: "avatar",
                  query: {
                    redirect: this.$route.query.redirect
                      ? `${this.$route.query.redirect}?redirect=install-app`
                      : "/account/install-app"
                  }
                });
              } else {
                this.$router.push({
                  name: "avatar",
                  query: { redirect: this.$route.query.redirect }
                });
              }
            } else {
              console.log(res.response);
              if (res.response && res.response.status === 422) {
                console.log(res.response.data.errors);
                this.errors = res.response.data.errors;
              }
            }
          })
          .catch(err => {
            console.log(err.response);
            this.errors = err.response;
          });
      });
    }
  }
};
</script>
