<template>
  <div class="pt-8 flex-1 flex flex-col bg-gray-primary-800">
    <div class="pb-24">
      <div class="flex items-center justify-between px-6">
        <div class="flex items-center">
          <router-link
            :to="{
              name: 'dashboard'
            }"
          >
            <svg
              class="w-8 h-8 text-white -ml-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </router-link>
        </div>

        <div>
          <router-link :to="{ name: 'account' }">
            <div
              class="h-12 w-12 bg-cover bg-center bg-cover rounded-4xl mx-auto"
              :style="`background-image:url('${user.profile_picture}')`"
            ></div>
          </router-link>
        </div>
      </div>
      <div v-if="selectedOffer">
        <div class="mt-8 px-12">
          <div
            class="text-3xl font-bold text-center flex justify-center text-white"
          >
            {{ selectedOffer.label }}
          </div>
          <div class="mt-1 text-sm px-8 text-center">
            <p class="text-sm text-white mb-2">
              {{ recurringLabel }}{{ limitUsageLabel }}{{ endAtLabel }}.
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-1/4 flex items-center justify-center">
            <button
              class="text-white focus:outline-none"
              @click="goToPreviousOffer"
              v-if="hasPreviousOffer"
            >
              <svg
                class="w-12 h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="w-1/2 flex items-center justify-center mt-8">
            <img
              :class="{ 'w-32 h-32': !selectedOffer.target }"
              :src="
                selectedOffer.target && selectedOffer.target.picto
                  ? selectedOffer.target.picto
                  : discountPicto
              "
            />
          </div>
          <div class="w-1/4 flex items-center justify-center">
            <button
              class="text-white focus:outline-none"
              @click="goToNextOffer"
              v-if="hasNextOffer"
            >
              <svg
                class="w-12 h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="px-6 mt-12">
          <button
            @click="openedOffer = selectedOffer"
            class="w-full text-white bg-green-500 flex items-center justify-center rounded-lg py-3 border border-transparent"
          >
            <span>{{ $t("offer.use_btn") }}</span>
          </button>
        </div>
        <div class="px-6 mt-8 flex justify-center">
          <button
            class="px-1 focus:outline-none"
            v-for="offer in offers"
            :key="offer.id"
            @click="selectedOfferUuid = offer.id"
          >
            <div
              class="rounded-full h-4 w-4"
              :class="{
                'bg-white': offer.id !== selectedOffer.id,
                'bg-green-500': offer.id === selectedOffer.id
              }"
            ></div>
          </button>
        </div>
      </div>
    </div>
    <unlock-modal
      :isOpen="openedOffer !== null"
      :offer="openedOffer"
      @close="openedOffer = null"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import QRious from "qrious";

import offerApi from "@/api/offer";

import UnlockModal from "@/components/Offers/UnlockModal";

export default {
  components: {
    UnlockModal
  },

  data() {
    return {
      selectedOfferUuid: null,
      offers: [],
      error: null,
      openedOffer: null
    };
  },

  mounted() {
    this.selectedOfferUuid = this.$route.params.id;
    this.getOffers();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("global", ["isIos", "isStandalone", "isIosStandalone"]),

    selectedOffer() {
      if (this.offers.length === 0) {
        return null;
      }
      return this.offers.filter(o => o.id === this.selectedOfferUuid)[0];
    },

    indexOfSelectedOffer() {
      if (!this.selectedOffer) {
        return null;
      }
      return this.offers.findIndex(o => o.id === this.selectedOffer.id);
    },

    hasPreviousOffer() {
      return this.indexOfSelectedOffer > 0;
    },

    hasNextOffer() {
      return this.indexOfSelectedOffer + 1 <= this.offers.length - 1;
    },

    recurringLabel() {
      if (!this.selectedOffer.is_recurring) {
        return this.$t("offer.usage_one_time");
      } else if (this.selectedOffer.nb_limit_usage) {
        return `${this.$t("offer.usage")} ${
          this.selectedOffer.nb_limit_usage
        } ${this.$t("offer.time")}`;
      } else {
        return this.$t("offer.usage_infinite");
      }
    },

    limitUsageLabel() {
      if (!this.selectedOffer.limit_usage) {
        return ", " + this.$t("offer.online_offline");
      } else if (this.selectedOffer.limit_usage === "online") {
        return ", " + this.$t("offer.online");
      } else if (this.selectedOffer.limit_usage === "on-site") {
        return ", " + this.$t("offer.offline");
      } else {
        return null;
      }
    },

    endAtLabel() {
      if (!this.selectedOffer.ended_at) {
        return ", " + this.$t("offer.no_time_limit");
      } else {
        return `, ${this.$t("offer.before_the")} ${new Date(
          this.selectedOffer.ended_at.replace(/ /g, "T")
        ).toLocaleString("fr-FR", {
          month: "short",
          day: "2-digit",
          year: "numeric"
        })}`;
      }
    },

    discountPicto() {
      return `${process.env.VUE_APP_API_URL}/imgs/star-struck.png`;
    }
  },

  methods: {
    getOffers() {
      offerApi
        .get({
          filter: "active-not-online",
          include: ""
        })
        .then(res => {
          this.offers = res.data;
        })
        .catch(err => {
          console.log(err.response);
          this.error = "Erreur";
        });
    },

    createQrCode() {
      new QRious({
        element: document.getElementById("qr"),
        value: `${this.card.scan_id}`,
        background: "white",
        foreground: "#000",
        size: 180
      });
    },

    goToPreviousOffer() {
      this.selectedOfferUuid = this.offers[this.indexOfSelectedOffer - 1].id;
    },

    goToNextOffer() {
      this.selectedOfferUuid = this.offers[this.indexOfSelectedOffer + 1].id;
    }
  }
};
</script>

<style lang="scss" scoped>
#qr {
  @apply rounded-md p-1 bg-white;
}
</style>
