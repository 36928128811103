<template>
  <router-link
    class="w-full"
    :to="{ name: 'prepaid-card-show', params: { id: prepaidCard.id } }"
  >
    <div class="w-full rounded-2xl bg-gray-primary-800 px-7 py-4">
      <div class="w-full text-right mt-1 mb-3">
        <p class="text-yellow-primary-500 font-medium uppercase">
          {{ $t("global.prepaid") }}
        </p>
      </div>
      <div class="w-full mt-4">
        <div class="w-10 h-7 bg-yellow-primary-500"></div>
      </div>

      <div class="w-full mt-4 mb-2 md:mt-8 md:mb-4">
        <p class="text-white text-base font-medium tracking-wide">
          {{ prepaidCardModel.name }}
        </p>
        <p class="text-white text-sm font-medium tracking-wide">
          {{ $t("cards.remaining") }} : {{ remainingScans }}
          {{ prepaidCardModel.scan_label }}
        </p>
        <p class="text-white text-xs font-light">
          {{ $t("cards.to_use_at") }} {{ company.brand }}
        </p>
        <div class="mt-4 h-2.5 relative max-w rounded-full overflow-hidden">
          <div class="w-full h-full rounded-full bg-black absolute"></div>
          <div
            class="transition-all ease-out duration-1000 h-full bg-yellow-primary-500 relative"
            :style="
              `width: ${(validHistories.length / prepaidCardModel.nb_scans) *
                100}%;`
            "
          ></div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },

  props: {
    prepaidCard: {
      type: Object,
      required: true
    },

    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    prepaidCardModel() {
      return this.prepaidCard.prepaidCardModel;
    },

    validHistories() {
      return this.prepaidCard.prepaidCardHistories.filter(h =>
        ["pending", "validated"].includes(h.state)
      );
    },

    remainingScans() {
      return this.prepaidCardModel.nb_scans - this.validHistories.length;
    }
  },

  methods: {}
};
</script>
