<template>
  <div class="pt-8 flex-1 flex flex-col bg-white">
    <div>
      <div class="flex items-center px-6">
        <router-link
          v-if="!isAndroid"
          :to="{ name: backRoute, params: { id: backId } }"
        >
          <svg
            class="w-8 h-8 text-gray-600"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </router-link>
        <div>
          <h1
            class="text-2xl font-medium text-gray-800"
            :class="{ 'ml-2': !isAndroid }"
          >
            {{ $t("account.install_app_section.title") }}
          </h1>
        </div>
      </div>
      <p class="text-gray-primary-500 px-6 mt-2 leading-5 font-medium">
        {{ $t("account.install_app_section.subtitle") }}
      </p>

      <install-ios-safari v-if="isIos" />
      <install-android-samsung v-else-if="isAndroid && isSamsung" />
      <install-android-firefox v-else-if="isAndroid && isFirefox" />
      <install-android-chrome v-else />

      <div class="w-full text-center py-2">
        <router-link
          class="text-blue-primary-500 hover:underline text-xs"
          :to="{
            name: backButtonRoute,
            params: { id: backButtonRouteId ? backButtonRouteId : null }
          }"
          >{{ $t("account.install_app_section.skip_step") }}</router-link
        >
      </div>
    </div>

    <div
      class="w-full bg-gray-100 pb-4 flex items-end justify-center flex-1"
      v-if="isIos"
    >
      <button
        class="rounded-full bg-blue-primary-500 text-white px-3 pt-7 pb-3"
      >
        <svg
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import InstallIosSafari from "@/components/Account/Install/IosSafari";
import InstallAndroidSamsung from "@/components/Account/Install/AndroidSamsung";
import InstallAndroidFirefox from "@/components/Account/Install/AndroidFirefox";
import InstallAndroidChrome from "@/components/Account/Install/AndroidChrome";

export default {
  components: {
    InstallIosSafari,
    InstallAndroidSamsung,
    InstallAndroidFirefox,
    InstallAndroidChrome
  },

  data() {
    return {
      backRoute: "account",
      backId: null
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "prepaid-card-show") {
        vm.backRoute = "prepaid-card-show";
        vm.backId = from.params.id;
      }
    });
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("global", ["isIos", "isAndroid", "isSamsung", "isFirefox"]),

    backButtonRoute() {
      if (!this.user) {
        return "dashboard";
      } else if (this.user.companies.length === 0) {
        return "dashboard";
      } else if (this.user.companies.length > 1) {
        return "company-cards";
      } else if (this.user.companies[0].contactPrepaidCards.length === 1) {
        return "prepaid-card-show";
      } else if (this.user.companies[0].contactLoyaltyCards.length === 1) {
        return "loyalty-card-show";
      } else {
        return "company-cards";
      }
    },

    backButtonRouteId() {
      if (!this.user) {
        return null;
      } else if (this.user.companies.length === 0) {
        return null;
      } else if (this.user.companies.length > 1) {
        return this.user.companies[0].id;
      } else if (this.user.companies[0].contactPrepaidCards.length === 1) {
        return this.user.companies[0].contactPrepaidCards[0].id;
      } else if (this.user.companies[0].contactLoyaltyCards.length === 1) {
        return this.user.companies[0].contactLoyaltyCards[0].id;
      } else {
        return this.user.companies[0].id;
      }
    }
  },

  methods: {}
};
</script>
