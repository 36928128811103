<template>
  <div>
    <div class="mt-1 relative z-30 w-24">
      <button
        @click="isOpen = !isOpen"
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="flex justify-center w-full bg-white border border-gray-300 rounded-md shadow-sm px-2 py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <img
          class="w-8 h-8"
          :src="require(`@/assets/imgs/flag/${selectedLocale.img}`)"
        />
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
        </span>
      </button>

      <!--
      Select popover, show/hide based on select state.

      Entering: ""
        From: ""
        To: ""
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg"
        v-show="isOpen"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
        -->
          <li
            v-for="locale in locales"
            :key="locale.label"
            id="listbox-item-0"
            role="option"
            class="text-gray-900 select-none relative py-2 px-2 hover:bg-gray-100 cursor-pointer"
            @click="change(locale)"
          >
            <div class="flex justify-center">
              <img
                class="w-8 h-8"
                :src="require(`@/assets/imgs/flag/${locale.img}`)"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import auth from "@/api/auth";

export default {
  data() {
    return {
      isOpen: false,
      locales: [
        {
          label: "locale.france",
          img: "france.svg",
          locale: "fr"
        },
        {
          label: "locale.uk",
          img: "united-kingdom.svg",
          locale: "en"
        },
        {
          label: "locale.it",
          img: "italy.svg",
          locale: "it"
        }
      ],
      selectedLocale: {
        label: "locale.france",
        img: "france.svg",
        locale: "fr"
      }
    };
  },

  mounted() {
    let currentLocale = null;
    if (this.user && this.user.locale) {
      currentLocale = this.locales.filter(
        l => l.locale === this.user.locale
      )[0];
    } else if (navigator.languages) {
      let lang = navigator.languages[0];
      if (lang.includes("-")) {
        lang = lang.split("-")[0];
      }

      let filterLocales = this.locales.filter(l => l.locale === lang);
      if (filterLocales.length > 0) {
        currentLocale = filterLocales[0];
      }
    }

    if (currentLocale) {
      this.selectedLocale = { ...currentLocale };
      this.$i18n.locale = currentLocale.locale;
    }
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    change(data) {
      this.$i18n.locale = data.locale;
      this.selectedLocale = data;
      this.isOpen = false;

      if (this.user) {
        auth.update({
          _method: "PUT",
          locale: data.locale
        });
      }
    }
  }
};
</script>
