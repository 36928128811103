import axios from "axios";

export default {
  cookie() {
    return axios.get("sanctum/csrf-cookie");
  },

  login(body) {
    return axios.post("api/contact/auth/login", body);
    // return axios.post("api/user/auth/login", body);
  },

  register(body) {
    return axios.post("api/contact/auth/register", body);
  },

  user(params = {}) {
    return axios.get("api/contact/auth/user", {
      params: params
    });
  },

  update(body) {
    return axios.post("api/contact/auth/user", body);
  },

  logout() {
    return axios.post("api/contact/auth/logout");
    // return axios.post("api/user/auth/logout");
  },

  askResetPassword(body) {
    return axios.post("api/contact/password/email", body);
  },

  updatePassword(body) {
    return axios.post("api/contact/password/update", body);
  }
};
