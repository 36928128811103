<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",

  computed: {
    ...mapState("auth", ["user"])
  },

  mounted() {
    // redirect to login or dashboard
    this.$router.push({
      name: this.user ? "dashboard" : "login"
    });
  }
};
</script>
