import axios from "axios";

export default {
  get(params = null) {
    return axios.get("api/contact/companies", {
      params: params
    });
  },

  getOne(uuid, params = null) {
    return axios.get(`api/contact/companies/${uuid}`, {
      params: params
    });
  }
};
