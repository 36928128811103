const state = {};

const getters = {
  isIos: () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },

  isAndroid: () => {
    return navigator.userAgent.includes("Android");
  },

  isSamsung: () => {
    return navigator.userAgent.includes("Samsung");
  },

  isFirefox: () => {
    return navigator.userAgent.includes("Firefox");
  },

  isStandalone: () => {
    const mqStandAlone = "(display-mode: standalone)";
    return navigator.standalone || window.matchMedia(mqStandAlone).matches;
  },

  isIosStandalone: () => {
    return navigator.standalone;
  }
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
