import axios from "axios";

export default {
  getOneCard(uuid, params = null) {
    return axios.get(`api/contact/loyalty-cards/${uuid}`, {
      params: params
    });
  },

  getOneProgram(uuid, params = null) {
    return axios.get(`api/contact/loyalty-programs/${uuid}`, {
      params: params
    });
  },

  joinLoyaltyProgram(uuid, lUuid) {
    return axios.post(`api/contact/loyalty-programs/${uuid}/location/${lUuid}`);
  }
};
