<template>
  <div class="pt-8 flex-1 flex flex-col bg-white">
    <div class="flex items-center px-6">
      <router-link :to="{ name: 'account' }">
        <svg
          class="w-8 h-8 text-gray-600 -ml-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>
      <div>
        <h1 class="text-2xl font-medium text-gray-800 ml-6">
          {{ $t("global.Password") }}
        </h1>
      </div>
    </div>

    <div class="w-full mt-6 px-6">
      <form action="#" method="POST" @submit.prevent="updatePassword">
        <div class="mt-1 relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <!-- Heroicon name: solid/lock-closed -->
            <svg
              class="mt-0.5 h-5 w-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              ></path>
            </svg>
          </div>
          <input
            v-model="password"
            id="password"
            :placeholder="$t('global.Password')"
            type="password"
            required
            class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
          />
        </div>
        <div class="mt-12 text-center">
          <button
            type="submit"
            class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
          >
            {{ $t("global.Confirm") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import auth from "@/api/auth";

export default {
  data() {
    return {
      password: ""
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    updatePassword() {
      auth
        .update({
          _method: "PUT",
          password: this.password
        })
        .then(res => {
          console.log(res.status);
          this.$router.push({
            name: "account"
          });
        })
        .catch(err => {
          console.log(err.response);
        });
    }
  }
};
</script>
