<template>
  <div
    class="min-h-screen bg-white flex flex-col items-center sm:px-0 relative bg-purple-primary-900"
  >
    <div class="w-full flex-1 sm:max-w-xl py-6 flex flex-col">
      <div>
        <div class="w-full">
          <img class="mx-auto" src="@/assets/imgs/hello.svg" />
        </div>

        <div class="mt-12 px-6 rounded-md sm:px-10">
          <div class="text-2xl font-semibold tracking-wider font-blogger-sans">
            {{ $t("auth.login.welcome") }},
          </div>
          <p class="text-gray-400 text-lg leading-5 mt-2 font-medium">
            {{ $t("auth.login.fill_form") }}
          </p>

          <div class="py-4">
            <div v-show="error">
              <p class="text-red-600">{{ error }}</p>
            </div>
            <form action="#" method="POST" @submit.prevent="login">
              <div class="mt-4 sm:mt-6">
                <label
                  for="email"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Email") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/mail -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="email"
                    id="email"
                    :placeholder="$t('global.Email')"
                    type="email"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
              </div>
              <div class="mt-4 sm:mt-6">
                <label
                  for="password"
                  class="hidden text-sm font-medium leading-5 text-gray-700"
                  >{{ $t("global.Password") }}</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <!-- Heroicon name: solid/lock-closed -->
                    <svg
                      class="mt-0.5 h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    v-model="password"
                    id="password"
                    type="password"
                    :placeholder="$t('global.Password')"
                    required
                    class="pl-12 bg-gray-primary-100 appearance-none block w-full py-5 px-4 rounded-lg text-gray-500 placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out font-medium sm:leading-5"
                  />
                </div>
              </div>
              <div class="mt-12 text-center">
                <button
                  type="submit"
                  class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
                >
                  {{ $t("auth.login.submit_button") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="text-center text-sm font-light text-gray-700">
        <p>
          {{ $t("auth.login.no_account") }}
          <router-link
            class="text-blue-primary-500 font-semibold"
            :to="{ name: 'register', query: Object.assign({}, $route.query) }"
            >{{ $t("auth.login.register") }}</router-link
          >
        </p>

        <p>
          <router-link
            class="text-blue-primary-500 font-semibold"
            :to="{ name: 'reset-password' }"
            >{{ $t("auth.password.reset_ask") }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import auth from "@/api/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: null
    };
  },

  mounted() {
    if (this.user) {
      this.$router.push({ name: "dashboard" });
    }
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    login() {
      const body = {
        email: this.email,
        password: this.password
      };

      auth.cookie().then(() => {
        this.$store
          .dispatch("auth/login", body)
          .then(res => {
            if (res.status && res.status === 200) {
              this.$store.dispatch("auth/user").then(({ data }) => {
                // set locale
                if (data.locale) {
                  this.$i18n.locale = data.locale;
                }

                if (data.companies.length === 1) {
                  if (
                    data.companies[0].contactPrepaidCards.length +
                      data.companies[0].contactLoyaltyCards.length +
                      data.companies[0].contactOffers.length >
                    1
                  ) {
                    this.$router.push(
                      this.$route.query.redirect || {
                        name: "company-cards",
                        params: { id: data.companies[0].id }
                      }
                    );
                  } else if (data.companies[0].contactPrepaidCards.length > 0) {
                    this.$router.push(
                      this.$route.query.redirect || {
                        name: "prepaid-card-show",
                        params: {
                          id: data.companies[0].contactPrepaidCards[0].id
                        }
                      }
                    );
                  } else if (data.companies[0].contactLoyaltyCards.length > 0) {
                    this.$router.push(
                      this.$route.query.redirect || {
                        name: "loyalty-card-show",
                        params: {
                          id: data.companies[0].contactLoyaltyCards[0].id
                        }
                      }
                    );
                  } else if (data.companies[0].contactOffers.length > 0) {
                    this.$router.push(
                      this.$route.query.redirect || {
                        name: "offer-show",
                        params: {
                          id: data.companies[0].contactOffers[0].id
                        }
                      }
                    );
                  } else {
                    this.$router.push(
                      this.$route.query.redirect || { name: "dashboard" }
                    );
                  }
                } else {
                  this.$router.push(
                    this.$route.query.redirect || { name: "dashboard" }
                  );
                }
              });
            } else {
              this.error = this.$t("auth.login.failed_auth");
            }
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  }
};
</script>
