<template>
  <div class="pt-8 flex-1 flex flex-col bg-white" v-show="!isLoading">
    <div class="flex items-center justify-between px-6">
      <router-link :to="{ name: 'dashboard' }">
        <svg
          v-if="showBackButton"
          class="w-8 h-8 text-gray-600 -ml-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>
      <div v-if="user">
        <router-link :to="{ name: 'account' }">
          <div
            class="h-12 w-12 bg-cover bg-center bg-cover rounded-4xl mx-auto"
            :style="`background-image:url('${user.profile_picture}')`"
          ></div>
        </router-link>
      </div>
    </div>

    <div class="px-6 mt-8" v-if="company">
      <h1 class="text-gray-800 text-3xl leading-7 tracking-wide font-medium">
        {{ $t("dashboard.my_cards") }}<br />
        {{ company.brand }}
      </h1>

      <div class="flex items-center mt-6">
        <div class="bg-yellow-primary-100 rounded-full p-1.5">
          <div class="bg-yellow-primary-700 h-2 w-2 rounded-full"></div>
        </div>
        <span class="text-base text-gray-700 ml-2">{{
          $t("dashboard.my_prepaid_cards")
        }}</span>
      </div>

      <div
        class="grid grid-flow-col overflow-x-auto gap-x-4 auto-cols-max mt-4 pb-1"
        :class="{ 'pr-10': prepaidCards.length > 1 }"
        style="grid-auto-columns: 100%;"
      >
        <prepaid-card
          v-for="prepaidCard in sortPrepaidCards"
          :key="prepaidCard.id"
          :company="company"
          :prepaidCard="prepaidCard"
        />
      </div>

      <div class="flex items-center mt-10">
        <div class="bg-pink-primary-100 rounded-full p-1.5">
          <div class="bg-pink-primary-700 h-2 w-2 rounded-full"></div>
        </div>
        <span class="text-base text-gray-700 ml-2">{{
          $t("dashboard.my_loyalty_cards")
        }}</span>
      </div>

      <div
        class="grid grid-flow-col overflow-x-auto gap-x-4 auto-cols-max mt-4 pb-1"
        :class="{ 'pr-10': loyaltyCards.length > 1 }"
        style="grid-auto-columns: 100%;"
      >
        <loyalty-card
          v-for="loyaltyCard in sortLoyaltyCards"
          :key="loyaltyCard.id"
          :company="company"
          :loyaltyCard="loyaltyCard"
        />
      </div>

      <div class="flex items-center mt-10">
        <div class="bg-green-100 rounded-full p-1.5">
          <div class="bg-green-400 h-2 w-2 rounded-full"></div>
        </div>
        <span class="text-base text-gray-700 ml-2">{{
          $t("dashboard.my_offers")
        }}</span>
      </div>

      <div
        class="grid grid-flow-col overflow-x-auto gap-x-4 auto-cols-max mt-4 pb-8"
        :class="{ 'pr-10': offers.length > 1 }"
        style="grid-auto-columns: 100%;"
      >
        <offer-card
          v-for="offer in sortOffers"
          :key="offer.id"
          :company="company"
          :offer="offer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import company from "@/api/company";

import PrepaidCard from "@/components/Cards/PrepaidCard";
import LoyaltyCard from "@/components/Cards/LoyaltyCard";
import OfferCard from "@/components/Cards/OfferCard";

export default {
  components: {
    PrepaidCard,
    LoyaltyCard,
    OfferCard
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },

  mounted() {
    this.companyUuid = this.$route.params.id;

    this.getCompany();
  },

  data() {
    return {
      companyUuid: null,
      company: null,
      prepaidCards: [],
      loyaltyCards: [],
      offers: [],
      prevRoute: null,
      isLoading: true
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    showBackButton() {
      return this.user ? this.user.companies.length > 1 : false;
    },

    sortPrepaidCards() {
      let orderedArr = [];

      let notOrdered = [
        ...this.prepaidCards.map(p => ({
          ...p,
          isFinish:
            p.prepaidCardModel.nb_scans -
              p.prepaidCardHistories.filter(h =>
                ["pending", "validated"].includes(h.state)
              ).length <
            1
        }))
      ];

      notOrdered.forEach(p => {
        if (!p.isFinish) {
          orderedArr.push(p);
        }
      });

      notOrdered.forEach(p => {
        if (p.isFinish) {
          orderedArr.push(p);
        }
      });

      return orderedArr;
    },

    sortLoyaltyCards() {
      return this.loyaltyCards;
    },

    sortOffers() {
      return this.offers;
    }
  },

  methods: {
    getCompany() {
      company
        .getOne(this.companyUuid, {
          filter: "active-not-online",
          include:
            "locations,contactPrepaidCards,contactLoyaltyCards,contactOffers,contactPrepaidCards.prepaidCardModel,contactPrepaidCards.prepaidCardHistories,contactOffers.contactOfferHistories"
        })
        .then(res => {
          console.log(res.data.contactOffers);
          this.company = res.data;
          this.prepaidCards = res.data.contactPrepaidCards;
          this.loyaltyCards = res.data.contactLoyaltyCards;
          this.offers = res.data.contactOffers;

          if (
            res.data.contactPrepaidCards.length === 1 &&
            res.data.contactOffers.length === 0 &&
            res.data.contactLoyaltyCards.length === 0
          ) {
            // the user has only one prepaid card
            this.$router.push(
              this.prevRoute.name === "prepaid-card-show"
                ? {
                    name: "dashboard"
                  }
                : {
                    name: "prepaid-card-show",
                    params: {
                      id: res.data.contactPrepaidCards[0].id
                    }
                  }
            );
          } else if (
            res.data.contactPrepaidCards.length === 0 &&
            res.data.contactOffers.length === 0 &&
            res.data.contactLoyaltyCards.length === 1
          ) {
            // the user has only one loyalty card
            this.$router.push(
              this.prevRoute.name === "loyalty-card-show"
                ? {
                    name: "dashboard"
                  }
                : {
                    name: "loyalty-card-show",
                    params: {
                      id: res.data.contactLoyaltyCards[0].id
                    }
                  }
            );
          } else {
            this.isLoading = false;
          }
        });
    }
  }
};
</script>
