import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import loader from "./modules/loader";
import global from "./modules/global";

Vue.use(Vuex);

const dataState = createPersistedState({
  reducer: state => {
    let reducer = Object.assign({}, state);
    // delete not needed reducer like : delete reducer.event;
    return reducer;
  }
});

export default new Vuex.Store({
  modules: {
    auth,
    loader,
    global
  },
  plugins: [dataState],
  strict: true
});
