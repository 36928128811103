<template>
  <div id="app" class="max-w-limit mx-auto border min-h-screen flex flex-col">
    <the-loader />
    <div class="w-auto px-2 py-1 flex justify-end" v-show="showLangPicker">
      <select-lang />
    </div>
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <router-view />
    </transition>

    <div
      class="max-w-limit fixed bottom-0 bg-gray-100 py-4 w-full text-center mx-auto"
      v-show="deferredPrompt && showBanner"
    >
      <p class="text-indigo-600 font-semibold">
        <a class="flex justify-center" href="#" @click="install">
          <svg
            class="w-6 h-6 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            ></path>
          </svg>
          <span class="text-lg">{{ $t("dashboard.install_application") }}</span>
        </a>
      </p>
    </div>

    <!-- New version modal -->
    <!-- <new-version
      @close="updateExists = false"
      @update="refreshApp"
      :isOpen="updateExists"
    /> -->
  </div>
</template>

<script>
import TheLoader from "@/components/TheLoader";
import SelectLang from "@/components/SelectLang";
// import NewVersion from "@/components/NewVersion";

// import swupdate from "@/mixins/swupdate";

export default {
  components: {
    TheLoader,
    SelectLang
    // NewVersion
  },

  // mixins: [swupdate],

  data() {
    return {
      deferredPrompt: null,
      showBanner: false
    };
  },

  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },

  computed: {
    showLangPicker() {
      return this.$route.meta.showLangPicker || false;
    }
  },

  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },

    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = "auto";
    },

    async install() {
      this.deferredPrompt.prompt();
      this.showBanner = false;
    }
  },

  watch: {
    "$route.name": {
      handler: function(val) {
        if (val === "install-app" && this.deferredPrompt) {
          this.showBanner = true;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
