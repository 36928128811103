<template>
  <div class="pt-8 flex-1 flex flex-col bg-white">
    <div class="flex items-center px-6">
      <router-link :to="{ name: 'account' }">
        <svg
          class="w-8 h-8 text-gray-600 -ml-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </router-link>
      <div>
        <h1 class="text-2xl font-medium text-gray-800 ml-6">
          {{ $t("account.how_it_works") }}
        </h1>
      </div>
    </div>

    <div class="w-full mt-6 px-6">
      <h2 class="text-xl font-medium text-gray-800 mb-2">
        {{ $t("account.how_it_works_section.prepaid_card_title") }}
      </h2>

      <p class="text-gray-700 text-sm">
        {{ $t("account.how_it_works_section.prepaid_card_content") }}
      </p>

      <h2 class="text-xl font-medium text-gray-800 mb-2 mt-4">
        {{ $t("account.how_it_works_section.auto_command_card_title") }}
      </h2>

      <p class="text-gray-700 text-sm">
        {{ $t("account.how_it_works_section.auto_command_card_content") }}
      </p>

      <h2 class="text-xl font-medium text-gray-800 mb-2 mt-4">
        {{ $t("account.how_it_works_section.loyalty_card_title") }}
      </h2>

      <p class="text-gray-700 text-sm">
        {{ $t("account.how_it_works_section.loyalty_card_content") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {}
};
</script>
