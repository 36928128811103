var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-8 pb-20 flex-1 flex flex-col bg-white px-6"},[(_vm.error)?_c('div',[_c('p',{staticClass:"text-red-primary-700"},[_vm._v(_vm._s(_vm.error))])]):(_vm.card)?_c('div',{staticClass:"flex flex-1 flex-col justify-center"},[(_vm.company.logo)?_c('div',{staticClass:"w-full h-16 mb-6"},[_c('img',{staticClass:"max-h-full mx-auto",attrs:{"src":_vm.company.logo}})]):_vm._e(),_c('div',{staticClass:"flex justify-center"},[_c('prepaid-card',{attrs:{"company":_vm.company,"prepaidCard":_vm.card}})],1),_c('div',{staticClass:"pb-6"},[_c('div',{staticClass:"mt-8 text-sm px-6 text-center"},[_c('p',{staticClass:"text-gray-800 text-2xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.just_used_invitation"))+" "),_c('br'),_c('span',{staticClass:"text-yellow-primary-500"},[_vm._v(_vm._s(_vm.$t("global.prepaid_card")))]),_c('br'),_vm._v(" "+_vm._s(_vm.company.brand)+" ")])])]),_c('div',{staticClass:"mt-4 mb-8 text-center"},[_c('router-link',{staticClass:"px-12 text-white bg-blue-primary-500 rounded-lg py-3.5 border border-transparent",attrs:{"to":{
          name: _vm.$route.query.redirect
            ? _vm.$route.query.redirect
            : 'prepaid-card-show',
          params: { id: _vm.card.id },
          query: { wallet: this.openAskDownloadPass }
        }}},[_c('span',[_vm._v("Ok")])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }