<template>
  <div
    v-show="isOpen"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-center justify-center min-h-screen text-center p-0">
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click="close"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        :class="{
          'ease-out duration-30 opacity-100': isOpen,
          'ease-in duration-200 opacity-0': !isOpen
        }"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        :class="{
          'opacity-100 translate-y-0 sm:scale-100': isOpen,
          'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !isOpen
        }"
        class="inline-block align-center bg-white rounded-2xl px-12 pt-5 pb-4 text-left shadow-xl transform transition-all max-w-limit w-full mx-4"
      >
        <div class="block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="close"
          >
            <span class="sr-only">Close</span>
            <svg
              class="h-6 w-6"
              x-description="Heroicon name: outline/x"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div v-if="card">
          <div
            class="mx-auto relative flex items-center justify-center h-32 w-32 -mt-20"
          >
            <img
              class="z-50 block w-20"
              src="@/assets/imgs/wallet_app_icon.png"
            />
          </div>
          <div class="text-center -mt-4">
            <h3
              class="text-2xl leading-6 font-medium text-gray-800"
              id="modal-title"
            >
              {{ $t("dashboard.ask_use_pass_title") }}
            </h3>
            <div class="mt-4">
              <p class="text-gray-600">
                {{ $t("dashboard.ask_use_pass_desc") }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <a
            :href="card.apple_pass"
            class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-3 border border-transparent"
          >
            {{ $t("dashboard.ask_use_pass_btn") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    card: {
      type: Object
    }
  },

  computed: {},

  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
