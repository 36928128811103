var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"fixed z-10 inset-0 overflow-y-auto",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"flex items-center justify-center min-h-screen text-center sm:block py-0 px-4"},[_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",class:{
        'ease-out duration-30 opacity-100': _vm.isOpen,
        'ease-in duration-200 opacity-0': !_vm.isOpen
      },attrs:{"aria-hidden":"true"},on:{"click":_vm.close}}),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-center bg-white rounded-2xl px-12 pt-5 pb-4 text-left shadow-xl transform transition-all max-w-limit w-full",class:{
        'opacity-100 translate-y-0 sm:scale-100': _vm.isOpen,
        'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !_vm.isOpen
      }},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"mx-auto relative flex items-center justify-center h-32 w-32 -mt-20"},[(_vm.gift)?_c('img',{staticClass:"z-50 block",attrs:{"src":_vm.gift.target ? _vm.gift.target.picto : _vm.discountPicto}}):_vm._e()]),_c('div',{staticClass:"text-center -mt-4"},[(_vm.gift)?_c('h3',{directives:[{name:"show",rawName:"v-show",value:(!_vm.gift.is_unlockable),expression:"!gift.is_unlockable"}],staticClass:"text-2xl leading-6 font-medium text-gray-800",attrs:{"id":"modal-title"}},[_vm._v(" "+_vm._s(_vm.gift.target ? _vm.gift.target.name : _vm.gift.label)+" ")]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.gift && _vm.gift.is_unlockable),expression:"gift && gift.is_unlockable"}],staticClass:"mt-4 text-center flex justify-center"},[_c('canvas',{attrs:{"id":"qr-gift"}})]),(_vm.gift && _vm.gift.is_unlockable)?_c('p',{staticClass:"text-sm font-medium text-gray-900 px-12 mt-8"},[_vm._v(" "+_vm._s(_vm.$t("loyalty.show_qr_to_unlock"))+" "+_vm._s(_vm.gift.target ? _vm.gift.target.name : _vm.gift.label)+" ")]):(_vm.gift)?_c('p',{staticClass:"text-sm font-medium text-gray-500 px-12 mt-4"},[_vm._v(" "+_vm._s(_vm.$t("loyalty.gift_not_unlockable"))+" "+_vm._s(_vm.gift.cost_points)+" points ")]):_vm._e()])])]),_c('div',{staticClass:"mt-5 sm:mt-6"},[_c('button',{staticClass:"w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-3 border border-transparent",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.gift && _vm.gift.is_unlockable ? _vm.$t("global.cancel") : _vm.$t("global.understand"))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }