<template>
  <div
    v-show="isOpen"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen text-center sm:block py-0 px-4"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click="close"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        :class="{
          'ease-out duration-30 opacity-100': isOpen,
          'ease-in duration-200 opacity-0': !isOpen
        }"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        :class="{
          'opacity-100 translate-y-0 sm:scale-100': isOpen,
          'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !isOpen
        }"
        class="inline-block align-center bg-white rounded-2xl px-12 pt-5 pb-4 text-left shadow-xl transform transition-all max-w-limit w-full"
      >
        <div class="block">
          <div
            class="mx-auto relative flex items-center justify-center h-32 w-32 -mt-20"
          >
            <img
              v-if="gift"
              class="z-50 block"
              :src="gift.target ? gift.target.picto : discountPicto"
            />
          </div>
          <div class="text-center -mt-4">
            <h3
              v-if="gift"
              v-show="!gift.is_unlockable"
              class="text-2xl leading-6 font-medium text-gray-800"
              id="modal-title"
            >
              {{ gift.target ? gift.target.name : gift.label }}
            </h3>
            <div class="mt-2">
              <div
                class="mt-4 text-center flex justify-center"
                v-show="gift && gift.is_unlockable"
              >
                <canvas id="qr-gift"></canvas>
              </div>
              <p
                class="text-sm font-medium text-gray-900 px-12 mt-8"
                v-if="gift && gift.is_unlockable"
              >
                {{ $t("loyalty.show_qr_to_unlock") }}
                {{ gift.target ? gift.target.name : gift.label }}
              </p>
              <p
                class="text-sm font-medium text-gray-500 px-12 mt-4"
                v-else-if="gift"
              >
                {{ $t("loyalty.gift_not_unlockable") }}
                {{ gift.cost_points }} points
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button
            type="button"
            @click.prevent="close"
            class="w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-3 border border-transparent"
          >
            {{
              gift && gift.is_unlockable
                ? $t("global.cancel")
                : $t("global.understand")
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRious from "qrious";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    card: {
      type: Object
    },
    gift: {
      type: Object
    }
  },

  computed: {
    discountPicto() {
      return `${process.env.VUE_APP_API_URL}/imgs/pictos/formule/express.png`;
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    createQrCode(url) {
      new QRious({
        element: document.getElementById("qr-gift"),
        value: url,
        background: "white",
        foreground: "#000",
        size: 180
      });
    }
  },

  watch: {
    isOpen: {
      handler(value) {
        if (value && this.gift && this.gift.is_unlockable) {
          this.createQrCode(`${this.gift.scan_id}?card=${this.card.id}`);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#qr {
  @apply rounded-md p-1 bg-white;
}
</style>
