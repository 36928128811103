var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"-mt-12 pt-8 flex-1 flex flex-col bg-white px-6"},[(_vm.error)?_c('div',[_c('div',{staticClass:"flex flex-col items-center justify-center"},[_vm._m(0),_c('div',{staticClass:"text-center px-16 mt-12"},[_c('p',{staticClass:"text-2xl font-semibold"},[_vm._v(_vm._s(_vm.$t("error.oh_no")))]),_c('p',{staticClass:"text-xl text-gray-500 mt-2"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]),_c('div',{staticClass:"mt-10 text-center w-full px-8"},[_c('router-link',{staticClass:"w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent",attrs:{"to":{
            name: _vm.backButtonRoute,
            params: { id: _vm.backButtonRouteId ? _vm.backButtonRouteId : null }
          }}},[_vm._v(" "+_vm._s(_vm.$t("error.back_on_dashboard"))+" ")])],1)])]):(_vm.loyaltyProgram && !_vm.isLoading)?_c('div',[_c('div',{staticClass:"w-full"},[(_vm.loyaltyProgram.company.logo)?_c('img',{staticClass:"mx-auto",attrs:{"src":_vm.loyaltyProgram.company.logo}}):_c('img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/imgs/cards-blue-black.svg")}})]),_c('div',{staticClass:"pb-6"},[_c('div',{staticClass:"mt-8 text-sm px-12 text-center"},[_c('p',{staticClass:"text-gray-800 text-2xl font-semibold"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.welcome"))+", ")]),_c('p',{staticClass:"text-lg text-gray-500 leading-5 mt-4"},[_vm._v(" "+_vm._s(_vm.$t("loyalty.you_will_accept_invitation"))+" ")])])]),_c('div',{staticClass:"mt-8 mb-8"},[_c('router-link',{staticClass:"w-full text-white bg-blue-primary-500 flex items-center justify-center rounded-lg py-3.5 border border-transparent",attrs:{"to":{
          name: 'register',
          query: { redirect: _vm.$router.currentRoute.fullPath }
        }}},[_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.register_invitation")))])])],1),_c('div',[_c('p',{staticClass:"text-center text-sm text-gray-700"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.already_register_invitation"))+" "),_c('router-link',{staticClass:"text-blue-primary-500 font-semibold",attrs:{"to":{
            name: 'login',
            query: { redirect: _vm.$router.currentRoute.fullPath }
          }}},[_vm._v(_vm._s(_vm.$t("dashboard.login_invitation")))])],1)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/imgs/404.png")}})])
}]

export { render, staticRenderFns }