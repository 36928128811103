<template>
  <div class="pt-8 flex-1 flex flex-col bg-gray-primary-800">
    <div class="pb-16">
      <div class="flex items-center justify-between px-6" v-if="company">
        <div class="flex items-center">
          <router-link
            v-if="backButtonRoute"
            :to="{
              name: backButtonRoute,
              params: { id: backButtonRouteId ? backButtonRouteId : null }
            }"
          >
            <svg
              class="w-8 h-8 text-white -ml-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </router-link>

          <div class="w-full h-8 ml-2" v-if="company.logo">
            <img class="max-h-full mx-auto" :src="company.logo" />
          </div>
        </div>

        <div>
          <router-link :to="{ name: 'account' }">
            <div
              class="h-12 w-12 bg-cover bg-center bg-cover rounded-4xl mx-auto"
              :style="`background-image:url('${user.profile_picture}')`"
            ></div>
          </router-link>
        </div>
      </div>
      <div class="mt-4 text-center flex justify-center">
        <canvas id="qr"></canvas>
      </div>
      <div class="mt-8 px-24 font-medium text-center flex justify-center">
        <p class="text-white">{{ $t("loyalty.show_qr") }}</p>
      </div>
    </div>

    <div class="bg-gray-primary-300 rounded-t-4xl">
      <div
        class="mt-6 text-sm px-8 text-center pb-14"
        v-if="lastProductGiftUnlockable"
      >
        <button
          @click="scrollToGift"
          type="button"
          class="w-full text-white text-lg bg-pink-primary-500 flex items-center justify-center rounded-lg py-4 border border-transparent"
        >
          {{ $t("loyalty.unlock") }}
          {{
            lastProductGiftUnlockable.target
              ? lastProductGiftUnlockable.target.name
              : lastProductGiftUnlockable.label
          }}
        </button>
      </div>
      <div
        class="mt-8 px-6 py-8 rounded-t-4xl -mt-8 bg-white flex-1"
        v-if="card"
      >
        <div class="w-full">
          <p class="text-2xl font-semibold tracking-wider">
            {{ $t("loyalty.your_card") }}
            <span class="text-pink-primary-500">{{
              $t("global.loyalty")
            }}</span>
            <br />
            {{ company.brand }}
          </p>
        </div>
        <div
          class="mt-4"
          v-if="lastProductGiftUnlockable && nextProductGiftUnlockable"
        >
          <p class="text-gray-400 text-base font-medium">
            {{ $t("global.your") }}
            {{
              lastProductGiftUnlockable.target
                ? lastProductGiftUnlockable.target.name
                : lastProductGiftUnlockable.label
            }}
            {{ $t("loyalty.is_wait_but") }}
            {{
              nextProductGiftUnlockable.target
                ? nextProductGiftUnlockable.target.name
                : nextProductGiftUnlockable.label
            }}
            !
          </p>
        </div>
        <div class="mt-8">
          <div
            class="mb-4 w-full"
            :class="{ 'text-right': !lastProductGiftUnlockable }"
          >
            <div
              v-if="nextProductGiftUnlockable"
              class="rounded inline px-3 py-1 text-white bg-green-primary-600"
              :style="
                `margin-left: ${(card.points_validated /
                  nextProductGiftUnlockable.cost_points) *
                  100 -
                  10}%;`
              "
            >
              {{ card.points_validated }} pts
            </div>
          </div>
          <div class="w-full self-center mb-4">
            <div class="h-2.5 relative max-w rounded-full overflow-hidden">
              <div
                class="w-full h-full rounded-full bg-gray-100 absolute"
              ></div>
              <div
                v-if="nextProductGiftUnlockable"
                class="transition-all ease-out duration-1000 h-full bg-green-primary-600 relative"
                :style="
                  `width: ${(card.points_validated /
                    nextProductGiftUnlockable.cost_points) *
                    100}%;`
                "
              ></div>
            </div>
          </div>

          <div
            class="flex justify-between items-center"
            :class="{ 'flex-row-reverse': !lastProductGiftUnlockable }"
          >
            <div
              v-if="lastProductGiftUnlockable"
              class="bg-pink-primary-500 text-white flex items-center px-2 py-2 text-sm rounded-r-2xl rounded-bl-2xl"
            >
              <div class="py-2 pr-2">
                <img
                  class="w-8"
                  :src="
                    lastProductGiftUnlockable.target
                      ? lastProductGiftUnlockable.target.picto
                      : discountPicto
                  "
                />
              </div>
              <div class="">
                {{
                  lastProductGiftUnlockable.target
                    ? lastProductGiftUnlockable.target.name
                    : lastProductGiftUnlockable.label
                }}<br />
                {{ lastProductGiftUnlockable.cost_points }} pts
              </div>
            </div>

            <div
              v-if="nextProductGiftUnlockable"
              class="bg-white text-black flex items-center px-2 py-2 text-sm rounded-l-2xl rounded-br-2xl border border-gray-300"
            >
              <div class="py-2 pr-2">
                <img
                  class="w-8"
                  :src="
                    nextProductGiftUnlockable.target
                      ? nextProductGiftUnlockable.target.picto
                      : discountPicto
                  "
                />
              </div>
              <div class="">
                {{
                  nextProductGiftUnlockable.target
                    ? nextProductGiftUnlockable.target.name
                    : nextProductGiftUnlockable.label
                }}<br />
                {{ nextProductGiftUnlockable.cost_points }} pts
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 mb-8" v-if="!isStandalone || isIosStandalone">
          <router-link
            v-if="!isStandalone"
            :to="{ name: 'install-app' }"
            class="w-full text-white bg-pink-primary-500 flex items-center justify-center rounded-lg py-3 border border-transparent"
          >
            <svg
              class="w-5 mr-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0    C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715    C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651    c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53    C512.919,81.362,504.815,71.632,493.815,70.629z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472    c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651    c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53    c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512    c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
                  />
                </g>
              </g>
            </svg>
            <span>{{ $t("account.install_app") }}</span>
          </router-link>

          <button
            v-if="card.apple_pass && (isIos || isIosStandalone)"
            @click="openPass"
            ref="openPass"
            class="mt-4 w-full text-gray-700 bg-white flex items-center justify-center rounded-lg py-3 border border-gray-400 font-medium"
          >
            <svg
              class="w-5 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              enable-background="new 0 0 48 48"
              height="24px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 48 48"
              width="24"
              xml:space="preserve"
            >
              <path
                clip-rule="evenodd"
                d="M47,40L47,40c0,2.762-2.238,5-5,5l0,0H6l0,0c-2.762,0-5-2.238-5-5V11  c0-2.209,1.791-4,4-4l0,0h20.171l8.099-2.934c0.513-0.187,1.081,0.078,1.268,0.589L35.391,7H39c2.209,0,4,1.791,4,4v2l0,0  c2.209,0,4,1.791,4,4V40z M5,9L5,9c-1.104,0-2,0.896-2,2s0.896,2,2,2h3.445l0,0h0.189c0.013-0.005,0.021-0.016,0.034-0.021L19.65,9  H5z M34.078,9.181l-1.062-2.924l-0.001,0v0L30.964,7h0.003l-5.514,2h-0.01l-11.039,4h21.062L34.078,9.181z M41,11  c0-1.104-0.896-2-2-2h-2.883l1.454,4H41l0,0V11z M43,15H5l0,0c-0.732,0-1.41-0.211-2-0.555V40c0,1.657,1.344,3,3,3h36  c1.657,0,3-1.343,3-3v-7h-4c-2.209,0-4-1.791-4-4s1.791-4,4-4h4v-8C45,15.896,44.104,15,43,15z M45,31v-4h-4c-1.104,0-2,0.896-2,2  s0.896,2,2,2H45z M41,28h2v2h-2V28z"
                fill-rule="evenodd"
              />
            </svg>
            <span>{{ $t("dashboard.see_wallet") }}</span>
          </button>
        </div>

        <div class="mt-8 mb-12">
          <h2 class="text-2xl font-semibold tracking-wider leading-7 mb-4">
            {{ $t("loyalty.available_gifts_1") }} <br />
            {{ $t("loyalty.available_gifts_2") }}
          </h2>

          <div
            class="flex flex-col mt-10"
            id="available-gifts"
            v-if="hasSortedGifts"
          >
            <a
              href="#"
              @click.prevent="useGift(gift)"
              class="flex items-stretch"
              v-for="(gift, i) in sortedGifts"
              :key="gift.id"
            >
              <div
                class="w-3 bg-gray-200 px-0.75"
                :class="{
                  'rounded-t-xl pt-0.75': i === 0,
                  'rounded-b-xl pb-0.75': i === sortedGifts.length - 1
                }"
              >
                <div
                  v-show="gift.cost_points <= card.points_validated"
                  class="h-full bg-green-primary-600 rounded-b-xl"
                  :class="{
                    'rounded-t-xl': i === 0
                  }"
                ></div>
              </div>
              <div class="px-8 py-3">
                <img
                  class="w-14"
                  :src="gift.target ? gift.target.picto : discountPicto"
                />
              </div>
              <div class="flex flex-col self-center">
                <p class="text-pink-primary-500 text-base font-medium">
                  {{ gift.cost_points }}pts
                </p>
                <p class="text-black text-lg font-medium">
                  {{ gift.target ? gift.target.name : gift.label }}
                </p>
              </div>
            </a>
          </div>

          <div v-else>
            <p class="text-gray-700 font-normal">
              {{ $t("loyalty.no_available_gifts") }}
            </p>
          </div>
        </div>

        <div class="mt-8 mb-12">
          <h2 class="text-2xl font-semibold tracking-wider leading-7 mb-4">
            {{ $t("dashboard.how_where") }}<br />
            {{ $t("dashboard.use_my_card") }} ? 😊
          </h2>

          <p class="text-gray-400 text-sm font-normal">
            {{ $t("loyalty.how_where_info") }}
          </p>

          <div class="mt-8">
            <div
              class="flex px-3 mb-12"
              v-for="location in locations"
              :key="location.id"
            >
              <div>
                <img
                  v-if="location.image"
                  class="rounded-lg w-16"
                  :src="location.image"
                />
                <div v-else class="w-16 h-16 rounded-lg bg-gray-100"></div>
              </div>
              <div class="pl-4">
                <p class="text-base font-semibold">{{ location.name }}</p>
                <p class="text-gray-400 text-sm font-normal leading-5">
                  {{ location.address }} <br />
                  {{ location.zip }} {{ location.city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <unlock-gift-modal
      :isOpen="openUnlockGiftModal"
      :gift="selectedGift"
      :card="card"
      @close="openUnlockGiftModal = false"
    />
    <ask-download-pass
      v-if="card"
      :isOpen="openAskDownloadPass"
      :card="card"
      @close="openAskDownloadPass = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import QRious from "qrious";

import UnlockGiftModal from "@/components/LoyaltyCards/UnlockGiftModal";
import AskDownloadPass from "@/components/LoyaltyCards/AskDownloadPass";

import loyaltyCard from "@/api/loyalty-card";

export default {
  components: {
    UnlockGiftModal,
    AskDownloadPass
  },

  data() {
    return {
      cardUuid: null,
      card: null,
      error: null,
      selectedGift: null,
      openUnlockGiftModal: false,
      openAskDownloadPass: false,
      canDownloadPass: false
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$nextTick(() => {
        if (from.name === "loyalty-card-confirm" && vm.isIos) {
          vm.openAskDownloadPass = true;
        }
        next();
      });
    });
  },

  mounted() {
    this.cardUuid = this.$route.params.id;
    this.getLoyaltyCard();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("global", ["isIos", "isStandalone", "isIosStandalone"]),

    company() {
      return this.card
        ? this.user.companies.filter(
            c => c.id === this.card.loyaltyProgram.company.id
          )[0]
        : null;
    },

    locations() {
      return this.card ? this.card.loyaltyProgram.locations : [];
    },

    discountPicto() {
      return `${process.env.VUE_APP_API_URL}/imgs/pictos/formule/express.png`;
    },

    sortedGifts() {
      return this.card
        ? [...this.card.loyalty_program_gifts]
            .filter(g => g.is_active)
            .sort(function(g1, g2) {
              return g1.cost_points - g2.cost_points;
            })
        : [];
    },

    productGifts() {
      return this.card
        ? [...this.card.loyalty_program_gifts]
            .filter(g => g.target)
            .sort((g1, g2) => {
              return g1.cost_points - g2.cost_points;
            })
        : [];
    },

    hasSortedGifts() {
      return this.sortedGifts.length > 0;
    },

    hasProductGifts() {
      return this.productGifts.length > 0;
    },

    lastProductGiftUnlockable() {
      if (this.sortedGifts.length === 0) {
        return null;
      }

      let unlockableGifts = this.sortedGifts.filter(
        p => p.cost_points <= this.card.points_validated
      );

      if (unlockableGifts.length === 0) {
        return null;
      }

      return unlockableGifts[unlockableGifts.length - 1];
    },

    nextProductGiftUnlockable() {
      if (!this.lastProductGiftUnlockable) {
        if (this.sortedGifts.length > 0) {
          return this.sortedGifts[0];
        } else {
          return null;
        }
      }

      let returnNext = false;
      let next = null;

      this.sortedGifts.forEach(p => {
        if (returnNext && !next) {
          next = p;
        } else if (p.id === this.lastProductGiftUnlockable.id) {
          returnNext = true;
        }
      });

      return next ? next : null;
    },

    backButtonRoute() {
      if (
        this.company.contactPrepaidCards.length === 0 &&
        this.company.contactOffers.length === 0 &&
        this.user.companies[0].contactLoyaltyCards.length === 1
      ) {
        if (this.user.companies.length > 1) {
          return "dashboard";
        } else {
          return null;
        }
      } else {
        return "company-cards";
      }
    },

    backButtonRouteId() {
      if (
        this.company.contactPrepaidCards.length === 0 &&
        this.company.contactOffers.length === 0 &&
        this.user.companies[0].contactLoyaltyCards.length === 1
      ) {
        return null;
      } else {
        return this.company.id;
      }
    }
  },

  methods: {
    getLoyaltyCard() {
      loyaltyCard
        .getOneCard(this.cardUuid, {
          include:
            "loyaltyProgram.company,loyaltyProgram,loyaltyProgram.locations"
        })
        .then(res => {
          this.card = res.data;
          this.createQrCode();
        })
        .catch(err => {
          console.log(err.response);
          this.error = "Carte introuvable";
        });
    },

    scrollToGift() {
      const el = this.$el.querySelector("#available-gifts");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    useGift(gift) {
      this.openUnlockGiftModal = true;
      this.selectedGift = {
        ...gift,
        is_unlockable: this.card.points_validated >= gift.cost_points
      };
    },

    createQrCode() {
      new QRious({
        element: document.getElementById("qr"),
        value: `${this.card.scan_id}`,
        background: "white",
        foreground: "#000",
        size: 180
      });
    },

    openPass() {
      if (this.canDownloadPass) {
        window.location.href = this.card.apple_pass;
        this.canDownloadPass = false;
      } else {
        this.$store.dispatch("auth/user").then(res => {
          if (res.status === 401) {
            this.$store.dispatch("auth/logout").then(() => {
              this.$router.push({ name: "login" });
            });
          } else {
            this.canDownloadPass = true;
            this.$refs.openPass.click();
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#qr {
  @apply rounded-md p-1 bg-white;
}
</style>
